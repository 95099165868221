import List from "../../components/List";
import Main from "../../components/Main";
import Message from "../../components/Message";
import Row from "../../components/Row";
import { DateTime } from "luxon";
import RestoreIcon from "@material-ui/icons/Restore";

const BackupsView = ({ error, backups, loadItem, createItem }) => {
  return (
    <Main
      className="Backups"
      path={["Backup del database"]}
      isParent
      error={error}
      mainActions={[
        {
          label: "Crea nuovo",
          type: "primary",
          effect: createItem,
        },
      ]}
    >
      <Message
        title=""
        message={`
          In questa sezione puoi creare backup del database, o caricare un backup passato.
        `}
      />
      <h2>Tutti i backup</h2>

      <List items={backups}>
        {(item) => {
          return (
            <Row
              key={item._id}
              title={DateTime.fromISO(item.date).toLocaleString(
                DateTime.DATETIME_SHORT_WITH_SECONDS
              )}
              actions={[
                {
                  title: "Ripristina",
                  icon: RestoreIcon,
                  callback: () => {
                    loadItem(item._id);
                  },
                },
              ]}
            />
          );
        }}
      </List>
    </Main>
  );
};
export default BackupsView;
