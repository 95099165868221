import List from "../../components/List";
import Main from "../../components/Main";
import Message from "../../components/Message";
import Row from "../../components/Row";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useHistory } from "react-router-dom";

const FrequentQuestionsView = ({
  error,
  frequentQuestions,
  deleteItem,
  orderHandler,
}) => {
  const history = useHistory();

  return (
    <Main
      className="FrequentQuestions"
      path={["Domande frequenti"]}
      isParent
      error={error}
      mainActions={[
        {
          label: "Crea nuovo",
          type: "primary",
          effect: () => {
            history.push("/frequentQuestions/new");
          },
        },
      ]}
    >
      <Message
        title=""
        message={`
          Le domande frequenti vengono visualizzate all'utente nella sezione apposita dell'app.
        `}
      />
      <h2>Tutte le domande frequenti</h2>

      <List items={frequentQuestions}>
        {(item) => {
          return (
            <Row
              to={`/frequentQuestions/${item._id}`}
              key={item._id}
              title={item.title}
              orderable
              orderHandler={orderHandler}
              id={item._id}
              actions={[
                {
                  title: "Modifica",
                  icon: EditIcon,
                  to: `/frequentQuestions/${item._id}`,
                },
                {
                  title: "Elimina",
                  icon: DeleteIcon,
                  callback: () => {
                    deleteItem(item._id);
                  },
                },
              ]}
            />
          );
        }}
      </List>
    </Main>
  );
};
export default FrequentQuestionsView;
