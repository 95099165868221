import { useState } from "react";
import { useHistory } from "react-router";
import "./index.scss";

const ResetPasswordRequestView = ({ action, isDone, isError }) => {
  const [email, setEmail] = useState("");
  const history = useHistory();

  return (
    <main className="ResetPasswordRequest">
      <button
        className="goBack"
        onClick={() => {
          history.goBack();
        }}
      >
        &lt; Torna indietro
      </button>
      {!isDone ? (
        <form
          className={`standardForm resetForm${isError ? " isError" : ""}`}
          onSubmit={(event) => {
            event.preventDefault();
            action(email);
          }}
        >
          <div className="formWrapper">
            <label>
              <span>Email</span>
              <input
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                name="email"
                type="email"
              />
            </label>
            <button type="submit">Richiedi il reset password</button>
          </div>
        </form>
      ) : (
        <div className="resetDone">
          <p>
            La richiesta è stata inviata: riceverai un link per poter inserire
            una nuova password. Controlla la tua email.
          </p>
        </div>
      )}
    </main>
  );
};

export default ResetPasswordRequestView;
