import { useState } from "react";
import apiClient from "../../api";
import ResetPasswordView from "./view";

const ResetPassword = (props) => {
  const [isDone, setIsDone] = useState(false);
  const [error, setError] = useState(false);
  const searchString = props.location.search;
  const params = new URLSearchParams(searchString);
  const token = params.get("token");

  const resetAction = async (newPassword) => {
    try {
      await apiClient["/auth/reset"].post({
        body: { newPassword, token },
      });
      setIsDone(true);
    } catch (error) {
      setError(error.details);
    }
  };

  return (
    <ResetPasswordView isDone={isDone} error={error} action={resetAction} />
  );
};

export default ResetPassword;
