import TextInput from "../../components/TextInput";
import RichtextInput from "../../components/RichtextInput";
import FileInput from "../../components/FileInput";
import ColContainer from "../../components/ColContainer";
import Col from "../../components/Col";
import { API_URL } from "../../config/api";
import apiClient from "../../api";
import langFields from "../../utils/langFields";

const OnboardingStepForm = ({ setItem, onboardingStep }) => {
  return (
    <>
      <ColContainer>
        {langFields((code, label) => {
          return (
            <Col type="1-3" key={code}>
              <TextInput
                id={`title${code}`}
                label={`Titolo${label}`}
                initialValue={onboardingStep[`title${code}`]}
                onChange={setItem}
              />
            </Col>
          );
        })}
      </ColContainer>
      <ColContainer>
        {langFields((code, label) => {
          return (
            <Col type="2-3" key={code}>
              <RichtextInput
                id={`text${code}`}
                label={`Testo${label}`}
                initialValue={onboardingStep[`text${code}`]}
                onChange={setItem}
              />
            </Col>
          );
        })}
      </ColContainer>

      <ColContainer>
        <Col type="1-3">
          <FileInput
            id="photoUrl"
            label="Illustrazione"
            hint="Preferibile SVG 400px x 400px"
            initialValue={onboardingStep.photoUrl}
            onChange={setItem}
            FileRenderer={({ fileId }) => {
              return (
                <img
                  style={{ height: "250px" }}
                  alt={fileId}
                  src={`${API_URL}/mediaElements/${fileId}`}
                />
              );
            }}
            uploadHandler={async (file) => {
              const formData = new FormData();
              formData.append("file", file);

              const fileId = await apiClient["/mediaElements"].post({
                body: formData,
              });
              return fileId;
            }}
          />
        </Col>
      </ColContainer>
    </>
  );
};

export default OnboardingStepForm;
