import { useState } from "react";
import Main from "../../components/Main";
import setItemFactory from "../../utils/setItemFactory";
import { useHistory } from "react-router-dom";
import FrequentQuestionForm from "./form";

const FrequentQuestionUpdateView = ({
  error,
  frequentQuestion,
  deleteItem,
  updateItem,
}) => {
  const [frequentQuestionObj, setFrequentQuestionObj] = useState(
    frequentQuestion
  );

  const setItem = setItemFactory(frequentQuestionObj, setFrequentQuestionObj);

  const history = useHistory();

  return frequentQuestion ? (
    <Main
      className="FrequentQuestion"
      path={["Domande frequenti", frequentQuestion.title]}
      error={error}
      mainActions={[
        {
          label: "Annulla",
          effect: () => {
            history.push("/frequentQuestions");
          },
        },
        {
          label: "Salva",
          type: "primary",
          effect: () => {
            updateItem(frequentQuestionObj);
          },
        },
      ]}
      secondaryActions={[
        {
          label: "Elimina questo elemento",
          type: "sensitive",
          effect: deleteItem,
        },
      ]}
    >
      <FrequentQuestionForm
        frequentQuestion={frequentQuestion}
        setItem={setItem}
      />
    </Main>
  ) : null;
};

export default FrequentQuestionUpdateView;
