import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import apiClient from "../../api";
import Popup from "../../components/Popup";
import BackupsView from "./view";

const Backups = () => {
  const [error, setError] = useState(false);
  const [backups, setBackups] = useState();
  const [popupData, setPopupData] = useState(false);

  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const backups = await apiClient["/backups"].get();
        setBackups(backups);
      } catch (error) {
        setError(error.details);
      }
    })();
  }, []);

  const createItem = async () => {
    try {
      await apiClient["/backups"].post({
        body: {},
      });
      const backups = await apiClient["/backups"].get();
      setBackups(backups);
    } catch (error) {
      setError(error.details);
    }
  };

  const loadItem = async (backupId) => {
    setPopupData({
      title: "Stai ripristinare questo backup",
      message:
        "Questa è un'operazione che sovrascriverà i dati ora presenti a database e che non potrai più annullare!",
      actions: [
        {
          label: "Annulla",
          effect: "close",
        },
        {
          label: "Ripristina e sovrascrivi",
          type: "sensitive",
          effect: async () => {
            try {
              await apiClient["/backups/{backupId}"].put({
                pathParams: { backupId },
                body: {},
              });
              setPopupData({
                title: "Operazione conclusa con successo",
                actions: [
                  {
                    label: "OK",
                    effect: () => {
                      history.push("/logout");
                    },
                    type: "primary",
                  },
                ],
              });
            } catch (error) {
              setError(error.details);
            }
          },
        },
      ],
    });
  };

  return (
    <>
      <BackupsView
        backups={backups}
        error={error}
        loadItem={loadItem}
        createItem={createItem}
      />
      {popupData && <Popup popupData={popupData} setPopupData={setPopupData} />}
    </>
  );
};

export default Backups;
