import { useState } from "react";
import Main from "../../components/Main";
import setItemFactory from "../../utils/setItemFactory";
import { useHistory } from "react-router-dom";
import FeatureForm from "./form";

const FeatureUpdateView = ({ error, feature, deleteItem, updateItem }) => {
  const [featureObj, setFeatureObj] = useState(feature);

  const setItem = setItemFactory(featureObj, setFeatureObj);

  const history = useHistory();

  return feature ? (
    <Main
      className="Feature"
      path={["Caratteristiche", feature.singularDisplayName]}
      error={error}
      mainActions={[
        {
          label: "Annulla",
          effect: () => {
            history.push("/features");
          },
        },
        {
          label: "Salva",
          type: "primary",
          effect: () => {
            updateItem(featureObj);
          },
        },
      ]}
      secondaryActions={[
        {
          label: "Elimina questo elemento",
          type: "sensitive",
          effect: deleteItem,
        },
      ]}
    >
      <FeatureForm feature={feature} setItem={setItem} />
    </Main>
  ) : null;
};

export default FeatureUpdateView;
