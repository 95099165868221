import { useState } from "react";
import Main from "../../components/Main";
import setItemFactory from "../../utils/setItemFactory";
import { useHistory } from "react-router-dom";
import FeatureForm from "./form";

const FeatureCreateView = ({ error, feature, createItem }) => {
  const [featureObj, setFeatureObj] = useState(feature);
  const setItem = setItemFactory(featureObj, setFeatureObj);

  const history = useHistory();

  return feature ? (
    <Main
      className="Feature"
      path={["Caratteristiche", "Crea nuovo"]}
      error={error}
      mainActions={[
        {
          label: "Annulla",
          effect: () => {
            history.push("/features");
          },
        },
        {
          label: "Salva",
          type: "primary",
          effect: () => {
            createItem(featureObj);
          },
        },
      ]}
    >
      <FeatureForm feature={feature} setItem={setItem} />
    </Main>
  ) : null;
};

export default FeatureCreateView;
