import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import apiClient from "../../api";
import Popup from "../../components/Popup";
import FeatureUpdateView from "./updateView";

const FeatureUpdate = () => {
  const [error, setError] = useState(false);
  const [feature, setFeature] = useState();
  const [popupData, setPopupData] = useState(false);
  const { id } = useParams();

  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const feature = await apiClient["/features/{featureId}"].get({
          pathParams: { featureId: id },
        });
        setFeature(feature);
      } catch (error) {
        setError(error.details);
      }
    })();
  }, [id]);

  const updateItem = async (data) => {
    delete data._id;
    try {
      await apiClient["/features/{featureId}"].put({
        pathParams: { featureId: id },
        body: data,
      });
      history.push("/features");
    } catch (error) {
      console.log(error);
      setError(error.details);
    }
  };

  const deleteItem = async () => {
    setPopupData({
      title: "Stai per eliminare l'elemento",
      message: "Questa è un'operazione che non potrai più annullare!",
      actions: [
        {
          label: "Annulla",
          effect: "close",
        },
        {
          label: "Elimina",
          type: "sensitive",
          effect: async () => {
            try {
              await apiClient["/features/{featureId}"].delete({
                pathParams: { featureId: id },
              });
              history.push("/features");
            } catch (error) {
              setError(error.details);
            }
          },
        },
      ],
    });
  };

  return feature ? (
    <>
      <FeatureUpdateView
        feature={feature}
        error={error}
        updateItem={updateItem}
        deleteItem={deleteItem}
      />
      {popupData && <Popup popupData={popupData} setPopupData={setPopupData} />}
    </>
  ) : null;
};

export default FeatureUpdate;
