import { useHistory } from "react-router";
import ErrorToast from "../ErrorToast";
import "./index.scss";

const Main = ({
  className,
  children,
  path,
  isParent,
  error,
  mainActions,
  secondaryActions,
}) => {
  let title;
  let breadcrumbItems;
  const history = useHistory();

  if (path) {
    title = path[path.length - 1];
    breadcrumbItems = path.filter((value, index) => index < path.length - 1);
  }

  return (
    <main className={`mainArea ${className}`}>
      {!isParent && (
        <button
          className="goBack"
          onClick={() => {
            history.goBack();
          }}
        >
          &lt; Torna indietro
        </button>
      )}
      {breadcrumbItems ? (
        <>
          <ul className="breadcrumb">
            {breadcrumbItems.map((breadcrumbItem) => {
              return <li key={breadcrumbItem}>{breadcrumbItem}</li>;
            })}
          </ul>
          <h1>{title}</h1>
        </>
      ) : null}
      <div>{children}</div>
      {mainActions && (
        <div className="mainActions">
          {mainActions.map((action, index) => {
            return (
              <button
                key={index}
                onClick={() => {
                  action.effect();
                }}
                className={action.type}
              >
                {action.label}
              </button>
            );
          })}
        </div>
      )}
      {secondaryActions && (
        <div className="secondaryActions">
          {secondaryActions.map((action, index) => {
            return (
              <button
                key={index}
                onClick={() => {
                  action.effect();
                }}
                className={action.type}
              >
                {action.label}
              </button>
            );
          })}
        </div>
      )}
      <ErrorToast error={error} />
    </main>
  );
};

export default Main;
