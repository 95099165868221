import { useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import "./index.scss";

const MultipleInput = ({
  id,
  disabled,
  label,
  hint,
  initialValue,
  error,
  onChange,
  children: childFunction,
  orderable,
  simpleItem,
  defaultSimpleItem,
}) => {
  if (!initialValue) initialValue = [];
  if (!defaultSimpleItem) defaultSimpleItem = "";

  const [currentItems, setCurrentItems] = useState(initialValue);
  const [version, setVersion] = useState(0);

  const changeHandler = (newValue, index, singleId) => {
    const newArray = [...currentItems];

    if (simpleItem) {
      newArray[index] = newValue;
    } else {
      newArray[index][singleId] = newValue;
    }

    setCurrentItems(newArray);
    onChange(newArray, id);
  };

  const deleteHandler = (index) => {
    const newArray = [...currentItems];
    newArray.splice(index, 1);
    setCurrentItems(newArray);
    setVersion(version + 1);
    onChange(newArray, id);
  };

  const addHandler = () => {
    const newArray = [...currentItems];
    newArray.push(simpleItem ? defaultSimpleItem : {});
    setCurrentItems(newArray);
    onChange(newArray, id);
  };

  const orderHandler = (up, index) => {
    //If the index is at the boundaries of the array, return, as there is nothing to swap
    if ((up && index === 0) || (!up && index === currentItems.length - 1))
      return;

    const otherIndex = up ? index - 1 : index + 1;

    const newArray = [...currentItems];
    [newArray[index], newArray[otherIndex]] = [
      newArray[otherIndex],
      newArray[index],
    ];

    setCurrentItems(newArray);
    setVersion(version + 1);
    onChange(newArray, id);
  };

  return (
    <div
      className={`MultipleInput ${disabled ? "disabled" : ""} ${
        simpleItem ? "simpleItem" : ""
      }`}
    >
      {label && <span className="labelText">{label}</span>}
      {hint && <span className="hintText">{hint}</span>}
      <div className="items">
        {currentItems.map((item, index) => {
          return (
            <div key={version + "-" + index} className="singleItem">
              <div className="actions">
                {orderable ? (
                  <>
                    <button
                      onClick={() => {
                        orderHandler(true, index);
                      }}
                    >
                      <KeyboardArrowUpIcon />
                    </button>
                    <button
                      onClick={() => {
                        orderHandler(false, index);
                      }}
                    >
                      <KeyboardArrowDownIcon />
                    </button>
                  </>
                ) : null}
                <button
                  onClick={() => {
                    deleteHandler(index);
                  }}
                >
                  <DeleteIcon />
                </button>
              </div>
              <div className="content">
                {childFunction(item, (newValue, singleId) =>
                  changeHandler(newValue, index, singleId)
                )}
              </div>
            </div>
          );
        })}
        <div className="singleItem addRow">
          <div className="actions">
            <button
              onClick={() => {
                addHandler();
              }}
            >
              <AddCircleIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultipleInput;
