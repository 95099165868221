import { useState } from "react";
import { Link } from "react-router-dom";
import ErrorToast from "../../components/ErrorToast";
import "./index.scss";

const ResetPasswordView = ({ action, isDone, error }) => {
  const [password, setPassword] = useState("");

  return (
    <main className="ResetPassword">
      {!isDone ? (
        <>
          <form
            className="standardForm resetForm"
            onSubmit={(event) => {
              event.preventDefault();
              action(password);
            }}
          >
            <div className="formWrapper">
              <label>
                <span>Nuova password</span>
                <input
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  name="password"
                  type="password"
                />
                <span className="hint">
                  La password deve contenere almeno 8 caratteri, una maiuscola,
                  una minuscola e un numero.
                </span>
              </label>
              <button type="submit">Invia la nuova password</button>
            </div>
          </form>
          <ErrorToast error={error} />
        </>
      ) : (
        <div className="resetDone">
          <p>
            La nuova password è stata impostata. Torna al{" "}
            <Link to="/">login</Link>.
          </p>
        </div>
      )}
    </main>
  );
};

export default ResetPasswordView;
