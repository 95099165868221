import { useEffect, useRef, useState } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import "./index.scss";
import useOutsideClick from "../../utils/useOutsideLink";

const SelectInput = ({
  id,
  disabled,
  label,
  hint,
  initialValue,
  error,
  onChange,
  items,
  valuePropertyName,
  displayPropertyName,
  subtitlePropertyName,
  nullable,
  searchable,
}) => {
  onChange = onChange ? onChange : () => {};

  const [currentValue, setCurrentValue] = useState(initialValue);
  const [currentList, setCurrentList] = useState(items);
  const [active, setActive] = useState(false);

  const ref = useRef();

  useOutsideClick(ref, () => {
    active && setActive(false);
  });

  useEffect(() => {
    setCurrentList(items);
  }, [items]);

  const changeHandler = (value) => {
    const newValue = value;
    setCurrentValue(newValue);
    setActive(false);
    onChange(newValue, id);
  };

  const searchHandler = (searchString) => {
    let results;

    if (searchString !== "") {
      results = items.filter((item) =>
        item[displayPropertyName]
          .toLowerCase()
          .includes(searchString.toLowerCase())
      );
    } else {
      results = items;
    }
    setCurrentList(results);
  };

  return (
    <label className="SelectInput" ref={ref}>
      {label && <span className="labelText">{label}</span>}
      <div className="selectBody">
        <div
          tabIndex="0"
          className="input"
          data-custom-id={id}
          onClick={() => {
            setActive(!active);
          }}
          onKeyDown={(event) => {
            if (event.key === " " || event.key === "Enter") {
              setActive(!active);
            }
            if (event.key === "Escape") {
              setActive(false);
            }
          }}
        >
          {currentList &&
          typeof currentList.find(
            (item) => item[valuePropertyName] === currentValue
          ) !== "undefined"
            ? currentList.find(
                (item) => item[valuePropertyName] === currentValue
              )[displayPropertyName]
            : ""}
        </div>
        <span className="selectIcon">
          <ArrowDropDownIcon />
        </span>
        <div
          className={`dropdown ${searchable ? "searchable" : ""}`}
          style={{ display: active ? "block" : "none" }}
        >
          {searchable && (
            <input
              className="searchInput"
              onChange={(event) => {
                const string = event.target.value || "";
                searchHandler(string);
              }}
            />
          )}
          <ul>
            {nullable && (
              <li
                onClick={() => {
                  changeHandler(null);
                }}
              >
                &lt;Rimuovi&gt;
              </li>
            )}
            {currentList
              ? currentList.map((item) => {
                  return (
                    <li
                      className={
                        item[valuePropertyName] === currentValue ? "active" : ""
                      }
                      key={item[valuePropertyName]}
                      onClick={() => {
                        changeHandler(item[valuePropertyName]);
                      }}
                    >
                      {item[displayPropertyName]}
                      {subtitlePropertyName && (
                        <div className="subtitle">
                          {item[subtitlePropertyName]}
                        </div>
                      )}
                    </li>
                  );
                })
              : null}
          </ul>
        </div>
      </div>
      {hint && <span className="hintText">{hint}</span>}
    </label>
  );
};

export default SelectInput;
