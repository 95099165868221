import SessionStore from "../../stores/SessionStore";
import "./index.scss";

const Logout = () => {
  SessionStore.destroy();
  setTimeout(() => {
    window.location = "/";
  }, 2000);

  return (
    <main className="Logout">
      <div className="logoutDone">
        <p>
          Logout effettuato. Torna al <a href="/">login</a>.
        </p>
      </div>
    </main>
  );
};

export default Logout;
