import langs from "../config/langs";

const langFields = (itemFunction) => {
  return (
    <>
      {langs.map((lang, index) => {
        if (index === 0) {
          return itemFunction("", "");
        }

        return itemFunction(`_${lang}`, ` (${lang.toUpperCase()})`);
      })}
    </>
  );
};

export default langFields;
