import List from "../../components/List";
import Main from "../../components/Main";
import Message from "../../components/Message";
import Row from "../../components/Row";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useHistory } from "react-router-dom";

const HierarchyItemsView = ({ error, hierarchyItems, deleteItem }) => {
  const history = useHistory();

  const HierarchyList = ({ hierarchyItems }) => {
    return (
      <List items={hierarchyItems}>
        {(item) => {
          return (
            <Row
              to={`/hierarchyItems/${item._id}`}
              key={item._id}
              title={item.singularDisplayName}
              actions={[
                {
                  title: "Modifica",
                  icon: EditIcon,
                  to: `/hierarchyItems/${item._id}`,
                },
                {
                  title: "Elimina",
                  icon: DeleteIcon,
                  callback: () => {
                    deleteItem(item._id);
                  },
                },
              ]}
            >
              {item.children && (
                <HierarchyList hierarchyItems={item.children} />
              )}
            </Row>
          );
        }}
      </List>
    );
  };

  return (
    <Main
      className="HierarchyItems"
      path={["Gerarchia"]}
      isParent
      error={error}
      mainActions={[
        {
          label: "Crea nuovo",
          type: "primary",
          effect: () => {
            history.push("/hierarchyItems/new");
          },
        },
      ]}
    >
      <Message
        title=""
        message={`
          Gli elementi della gerarchia determinano la struttura globale dell'applicazione.
          La gerarchia è per esempio utilizzata per determinare i livelli di navigazione della funzionalità "esplora" dell'applicazione.
      `}
      />
      <Message
        title="La modifica della gerarchia è un'operazione rischiosa!"
        type="warning"
        message="
            Gli elementi di gerarchia sono un componente sensibile della struttura dati di questa applicazione. 
            Modifica questi dati solo ed esclusivamente se sei consapevole di cosa stai facendo, 
            e di quali sono le conseguenze delle tue azioni.
        "
      />
      <h2>Tutti gli elementi di gerarchia</h2>
      <HierarchyList hierarchyItems={hierarchyItems} />
    </Main>
  );
};
export default HierarchyItemsView;
