import { useEffect, useState } from "react";
import apiClient from "../../api";
import Popup from "../../components/Popup";
import DirectionTemplatesView from "./view";

const DirectionTemplates = () => {
  const [error, setError] = useState(false);
  const [directionTemplates, setDirectionTemplates] = useState();
  const [popupData, setPopupData] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const directionTemplates = await apiClient["/directionTemplates"].get();
        setDirectionTemplates(directionTemplates);
      } catch (error) {
        setError(error.details);
      }
    })();
  }, []);

  const deleteItem = async (directionTemplateId) => {
    setPopupData({
      title: "Stai per eliminare l'elemento",
      message: "Questa è un'operazione che non potrai più annullare!",
      actions: [
        {
          label: "Annulla",
          effect: "close",
        },
        {
          label: "Elimina",
          type: "sensitive",
          effect: async () => {
            try {
              await apiClient[
                "/directionTemplates/{directionTemplateId}"
              ].delete({
                pathParams: { directionTemplateId },
              });
              const directionTemplates = await apiClient[
                "/directionTemplates"
              ].get();
              setDirectionTemplates(directionTemplates);
            } catch (error) {
              setError(error.details);
            }
          },
        },
      ],
    });
  };

  return (
    <>
      <DirectionTemplatesView
        directionTemplates={directionTemplates}
        error={error}
        deleteItem={deleteItem}
      />
      {popupData && <Popup popupData={popupData} setPopupData={setPopupData} />}
    </>
  );
};

export default DirectionTemplates;
