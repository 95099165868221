import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import apiClient from "../../api";
import Popup from "../../components/Popup";
import DirectionTemplateUpdateView from "./updateView";

const DirectionTemplateUpdate = () => {
  const [error, setError] = useState(false);
  const [directionTemplate, setDirectionTemplate] = useState();
  const [popupData, setPopupData] = useState(false);
  const { id } = useParams();

  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const directionTemplate = await apiClient[
          "/directionTemplates/{directionTemplateId}"
        ].get({
          pathParams: { directionTemplateId: id },
        });
        setDirectionTemplate(directionTemplate);
      } catch (error) {
        setError(error.details);
      }
    })();
  }, [id]);

  const updateItem = async ({ displayName, steps }) => {
    console.log(steps);
    const data = {
      displayName,
      steps,
    };
    try {
      await apiClient["/directionTemplates/{directionTemplateId}"].put({
        pathParams: { directionTemplateId: id },
        body: data,
      });
      history.push("/directionTemplates");
    } catch (error) {
      console.log(error);
      setError(error.details);
    }
  };

  const deleteItem = async () => {
    setPopupData({
      title: "Stai per eliminare l'elemento",
      message: "Questa è un'operazione che non potrai più annullare!",
      actions: [
        {
          label: "Annulla",
          effect: "close",
        },
        {
          label: "Elimina",
          type: "sensitive",
          effect: async () => {
            try {
              await apiClient[
                "/directionTemplates/{directionTemplateId}"
              ].delete({
                pathParams: { directionTemplateId: id },
              });
              history.push("/directionTemplates");
            } catch (error) {
              setError(error.details);
            }
          },
        },
      ],
    });
  };

  return directionTemplate ? (
    <>
      <DirectionTemplateUpdateView
        directionTemplate={directionTemplate}
        error={error}
        updateItem={updateItem}
        deleteItem={deleteItem}
      />
      {popupData && <Popup popupData={popupData} setPopupData={setPopupData} />}
    </>
  ) : null;
};

export default DirectionTemplateUpdate;
