import "./index.scss";

const Message = ({ type, title, message }) => {
  const defaultTitles = {
    info: "Informazione",
    warning: "Attenzione",
  };

  type = type || "info";
  if (title !== "") title = title || defaultTitles[type] || "Messaggio";

  return (
    <div className={`Message ${type}`}>
      {title ? <p className="title">{title}</p> : null}
      <p>{message}</p>
    </div>
  );
};

export default Message;
