import { createClient } from "oa-client";
import { API_URL } from "../config/api";
import SessionStore from "../stores/SessionStore";
import specs from "./openapi.json";

const callers = {
  "default-get": async (url) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    const response = await fetch(url, {
      method: "GET",
      headers,
    });

    if (!response.ok) {
      const error = new Error();
      error.details = (await response.json()).error;
      throw error;
    }

    try {
      const json = await response.json();
      return json;
    } catch (error) {
      return null;
    }
  },
  "authorized-get": async (url) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      `Bearer ${(await SessionStore.get()).access_token}`
    );
    const response = await fetch(url, {
      method: "GET",
      headers,
    });

    if (!response.ok) {
      const error = new Error();
      error.details = (await response.json()).error;
      throw error;
    }

    try {
      const json = await response.json();
      return json;
    } catch (error) {
      return null;
    }
  },
  "default-post": async (url, body) => {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      const error = new Error();
      error.details = (await response.json()).error;
      throw error;
    }

    try {
      const json = await response.json();
      return json;
    } catch (error) {
      return null;
    }
  },
  "urlencoded-post": async (url, body) => {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams(body),
    });

    if (!response.ok) {
      const error = new Error();
      error.details = (await response.json()).error;
      throw error;
    }

    try {
      const json = await response.json();
      return json;
    } catch (error) {
      return null;
    }
  },
  "authorized-post": async (url, body) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      `Bearer ${(await SessionStore.get()).access_token}`
    );
    const response = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      const error = new Error();
      error.details = (await response.json()).error;
      throw error;
    }

    try {
      const json = await response.json();
      return json;
    } catch (error) {
      return null;
    }
  },
  "authorized-file-post": async (url, formData) => {
    const headers = new Headers();
    headers.append(
      "Authorization",
      `Bearer ${(await SessionStore.get()).access_token}`
    );

    const response = await fetch(url, {
      method: "POST",
      headers,
      body: formData,
    });

    if (!response.ok) {
      const error = new Error();
      error.details = (await response.json()).error;
      throw error;
    }

    try {
      const json = await response.json();
      return json;
    } catch (error) {
      return null;
    }
  },

  "authorized-delete": async (url) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      `Bearer ${(await SessionStore.get()).access_token}`
    );
    const response = await fetch(url, {
      method: "DELETE",
      headers,
    });

    if (!response.ok) {
      const error = new Error();
      error.details = (await response.json()).error;
      throw error;
    }

    try {
      const json = await response.json();
      return json;
    } catch (error) {
      return null;
    }
  },

  "authorized-put": async (url, body) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      `Bearer ${(await SessionStore.get()).access_token}`
    );
    const response = await fetch(url, {
      method: "PUT",
      headers,
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      const error = new Error();
      error.details = (await response.json()).error;
      throw error;
    }

    try {
      const json = await response.json();
      return json;
    } catch (error) {
      return null;
    }
  },
};

const apiClient = createClient(specs, callers, {
  origin: API_URL,
  validationLevel: "off",
});

export default apiClient;
