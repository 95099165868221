import Message from "../../components/Message";
import TextInput from "../../components/TextInput";
import MultipleInput from "../../components/MultipleInput";
import SelectInput from "../../components/SelectInput";
import RichtextInput from "../../components/RichtextInput";
import ColContainer from "../../components/ColContainer";
import Col from "../../components/Col";
import FileInput from "../../components/FileInput";
import { API_URL } from "../../config/api";
import apiClient from "../../api";
import langFields from "../../utils/langFields";

const DirectionTemplateForm = ({ setItem, directionTemplate }) => {
  return (
    <>
      <ColContainer>
        <Col type="1-3">
          <TextInput
            id="displayName"
            label="Nome"
            hint="Un nome per facilitare l'uso del template nell'interfaccia di admin."
            initialValue={directionTemplate.displayName}
            onChange={setItem}
          />
        </Col>
      </ColContainer>
      <ColContainer>
        <Col type="1-1">
          <MultipleInput
            id="steps"
            label="Step"
            hint="Elenca qui gli step che compongono questo template ripetibile"
            initialValue={directionTemplate.steps}
            onChange={setItem}
            orderable
          >
            {(item, changeHandler) => {
              return (
                <>
                  <ColContainer>
                    <Col type="1-3">
                      <SelectInput
                        id="type"
                        label="Tipo di step"
                        initialValue={item.type}
                        onChange={changeHandler}
                        items={[
                          { value: "EXTERNAL", label: "Riferimento esterno" },
                          { value: "GRAPHICS", label: "Infografica o mappa" },
                          { value: "PHOTO", label: "Fotografia" },
                          { value: "TEXT", label: "Testo" },
                        ]}
                        valuePropertyName="value"
                        displayPropertyName="label"
                      />
                    </Col>
                  </ColContainer>
                  {item.type === "GRAPHICS" ? (
                    <ColContainer>
                      {langFields((code, label) => {
                        return (
                          <Col type="1-3" key={code}>
                            <FileInput
                              id={`photoUrl${code}`}
                              label={`Immagine${label}`}
                              hint="Preferibile SVG 400px x 400px"
                              initialValue={item[`photoUrl${code}`]}
                              onChange={changeHandler}
                              FileRenderer={({ fileId }) => {
                                return (
                                  <img
                                    style={{ height: "250px" }}
                                    alt={fileId}
                                    src={`${API_URL}/mediaElements/${fileId}`}
                                  />
                                );
                              }}
                              uploadHandler={async (file) => {
                                const formData = new FormData();
                                formData.append("file", file);

                                const fileId = await apiClient[
                                  "/mediaElements"
                                ].post({
                                  body: formData,
                                });
                                console.log(fileId);
                                return fileId;
                              }}
                            />
                          </Col>
                        );
                      })}
                    </ColContainer>
                  ) : null}
                  {item.type === "PHOTO" ? (
                    <ColContainer>
                      {langFields((code, label) => {
                        return (
                          <Col type="1-3" key={code}>
                            <FileInput
                              id={`photoUrl${code}`}
                              label={`Fotografia${label}`}
                              hint="Preferibile JPEG 1080px x 1080px"
                              initialValue={item[`photoUrl${code}`]}
                              onChange={changeHandler}
                              FileRenderer={({ fileId }) => {
                                return (
                                  <img
                                    style={{ height: "250px" }}
                                    alt={fileId}
                                    src={`${API_URL}/mediaElements/${fileId}`}
                                  />
                                );
                              }}
                              uploadHandler={async (file) => {
                                const formData = new FormData();
                                formData.append("file", file);

                                const fileId = await apiClient[
                                  "/mediaElements"
                                ].post({
                                  body: formData,
                                });
                                return fileId;
                              }}
                            />
                          </Col>
                        );
                      })}
                    </ColContainer>
                  ) : null}
                  {item.type === "TEXT" ? (
                    <ColContainer>
                      {langFields((code, label) => {
                        return (
                          <Col type="2-3" key={code}>
                            <RichtextInput
                              id={`text${code}`}
                              label={`Testo${label}`}
                              initialValue={item[`text${code}`]}
                              onChange={changeHandler}
                            />
                          </Col>
                        );
                      })}
                    </ColContainer>
                  ) : null}
                  {item.type === "EXTERNAL" ? (
                    <>
                      <ColContainer>
                        <Col type="2-3">
                          <Message
                            title=""
                            message={
                              "Inserisci l'indirizzo e le coordinate del punto di entrata."
                            }
                          />
                        </Col>
                      </ColContainer>
                      <ColContainer>
                        <Col type="1-3">
                          <TextInput
                            id="lat"
                            label="Latitudine"
                            initialValue={item.lat}
                            onChange={changeHandler}
                            parse="number"
                          />
                        </Col>
                        <Col type="1-3">
                          <TextInput
                            id="long"
                            label="Longitudine"
                            initialValue={item.long}
                            onChange={changeHandler}
                            parse="number"
                          />
                        </Col>
                      </ColContainer>{" "}
                      <ColContainer>
                        <Col type="2-3">
                          <TextInput
                            id="addressDisplayName"
                            label="Indirizzo"
                            hint="Inserisci l'indirizzo completo (es. Via Giosuè Carducci 11 - Milano MI)"
                            initialValue={item.addressDisplayName}
                            onChange={changeHandler}
                          />
                        </Col>
                      </ColContainer>
                      {item.addressDisplayName ? (
                        <ColContainer>
                          <Col type="2-3">
                            <p>Indirizzi generati:</p>
                            <ul>
                              <li>
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                                    item.addressDisplayName
                                  )}`}
                                >
                                  Google Maps
                                </a>
                              </li>
                              <li>
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={`https://citymapper.com/directions?endcoord=${item.lat}%2C${item.long}&endaddress=${item.addressDisplayName}`}
                                >
                                  Citymapper
                                </a>
                              </li>
                            </ul>
                          </Col>
                        </ColContainer>
                      ) : null}
                    </>
                  ) : null}
                </>
              );
            }}
          </MultipleInput>
        </Col>
      </ColContainer>
    </>
  );
};

export default DirectionTemplateForm;
