import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import apiClient from "../../api";
import Popup from "../../components/Popup";
import HierarchyItemCreateView from "./createView";

const HierarchyItemCreate = () => {
  const [error, setError] = useState(false);
  const [hierarchyItemList, setHierarchyItemList] = useState();
  const [popupData, setPopupData] = useState(false);

  const history = useHistory();

  const hierarchyItem = {};

  useEffect(() => {
    (async () => {
      try {
        const hierarchyItemList = await apiClient["/hierarchyItems"].get({
          queryParams: { flat: true },
        });
        setHierarchyItemList(hierarchyItemList);
      } catch (error) {
        setError(error.details);
      }
    })();
  }, []);

  const createItem = async (data) => {
    delete data._id;
    try {
      await apiClient["/hierarchyItems"].post({
        body: data,
      });
      history.push("/hierarchyItems");
    } catch (error) {
      console.log(error);
      setError(error.details);
    }
  };

  return hierarchyItem ? (
    <>
      <HierarchyItemCreateView
        hierarchyItem={hierarchyItem}
        hierarchyItemList={hierarchyItemList}
        error={error}
        createItem={createItem}
      />
      {popupData && <Popup popupData={popupData} setPopupData={setPopupData} />}
    </>
  ) : null;
};

export default HierarchyItemCreate;
