import { useState } from "react";
import Main from "../../components/Main";
import setItemFactory from "../../utils/setItemFactory";
import { useHistory } from "react-router-dom";
import OnboardingStepForm from "./form";

const OnboardingStepCreateView = ({ error, onboardingStep, createItem }) => {
  const [onboardingStepObj, setOnboardingStepObj] = useState(onboardingStep);
  const setItem = setItemFactory(onboardingStepObj, setOnboardingStepObj);

  const history = useHistory();

  return onboardingStep ? (
    <Main
      className="OnboardingStep"
      path={["Step di onboarding", "Crea nuovo"]}
      error={error}
      mainActions={[
        {
          label: "Annulla",
          effect: () => {
            history.push("/onboardingSteps");
          },
        },
        {
          label: "Salva",
          type: "primary",
          effect: () => {
            createItem(onboardingStepObj);
          },
        },
      ]}
    >
      <OnboardingStepForm onboardingStep={onboardingStep} setItem={setItem} />
    </Main>
  ) : null;
};

export default OnboardingStepCreateView;
