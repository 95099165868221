import { useEffect, useState } from "react";
import apiClient from "../../api";
import Popup from "../../components/Popup";
import SpacesView from "./view";

const Spaces = () => {
  const [error, setError] = useState(false);
  const [spaces, setSpaces] = useState();
  const [popupData, setPopupData] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        let spaces = await apiClient["/spaces"].get();
        spaces = spaces
          .map((space) => {
            let newObj = { ...space };
            newObj.pathText = space.path.reduce((prev, curr, index) => {
              if (index === space.path.length - 2) {
                return prev + curr.displayName;
              } else if (index === space.path.length - 1) {
                return prev;
              } else {
                return prev + curr.displayName + " > ";
              }
            }, "");

            return newObj;
          })
          .sort((a, b) => {
            let nameA = a.pathText.toLowerCase(),
              nameB = b.pathText.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          });
        setSpaces(spaces);
      } catch (error) {
        setError(error.details);
      }
    })();
  }, []);

  const deleteItem = async (spaceId) => {
    setPopupData({
      title: "Stai per eliminare l'elemento",
      message: "Questa è un'operazione che non potrai più annullare!",
      actions: [
        {
          label: "Annulla",
          effect: "close",
        },
        {
          label: "Elimina",
          type: "sensitive",
          effect: async () => {
            try {
              await apiClient["/spaces/{spaceId}"].delete({
                pathParams: { spaceId },
              });
              const spaces = await apiClient["/spaces"].get();
              setSpaces(spaces);
            } catch (error) {
              setError(error.details);
            }
          },
        },
      ],
    });
  };

  return (
    <>
      {spaces ? <SpacesView spaces={spaces} error={error} deleteItem={deleteItem} /> : <div style={{textAlign: "center", padding: "20px"}}>Loading...</div>}
      {popupData && <Popup popupData={popupData} setPopupData={setPopupData} />}
    </>
  );
};

export default Spaces;
