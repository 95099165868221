import List from "../../components/List";
import Main from "../../components/Main";
import Message from "../../components/Message";
import Row from "../../components/Row";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useHistory } from "react-router-dom";

const LabelsView = ({ error, labels, deleteItem }) => {
  const history = useHistory();

  return (
    <Main
      className="Labels"
      path={["Etichette"]}
      isParent
      error={error}
      mainActions={[
        {
          label: "Crea nuovo",
          type: "primary",
          effect: () => {
            history.push("/labels/new");
          },
        },
      ]}
    >
      <Message
        title=""
        message={`
          Le etichette possono essere associate agli spazi, e sono utilizzate dagli utenti per cercare gli spazi sulla base di categorie.
      `}
      />
      <h2>Tutte le etichette</h2>

      <List items={labels}>
        {(item) => {
          return (
            <Row
              to={`/labels/${item._id}`}
              key={item._id}
              title={item.singularDisplayName}
              actions={[
                {
                  title: "Modifica",
                  icon: EditIcon,
                  to: `/labels/${item._id}`,
                },
                {
                  title: "Elimina",
                  icon: DeleteIcon,
                  callback: () => {
                    deleteItem(item._id);
                  },
                },
              ]}
            />
          );
        }}
      </List>
    </Main>
  );
};
export default LabelsView;
