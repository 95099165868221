import { useState } from "react";
import { useHistory } from "react-router-dom";
import apiClient from "../../api";
import Popup from "../../components/Popup";
import AnnouncementCreateView from "./createView";

const AnnouncementCreate = () => {
  const [error, setError] = useState(false);
  const [popupData, setPopupData] = useState(false);

  const history = useHistory();

  const announcement = {};

  const createItem = async (data) => {
    delete data._id;
    try {
      await apiClient["/announcements"].post({
        body: data,
      });
      history.push("/announcements");
    } catch (error) {
      console.log(error);
      setError(error.details);
    }
  };

  return announcement ? (
    <>
      <AnnouncementCreateView
        announcement={announcement}
        error={error}
        createItem={createItem}
      />
      {popupData && <Popup popupData={popupData} setPopupData={setPopupData} />}
    </>
  ) : null;
};

export default AnnouncementCreate;
