import { useState } from "react";
import Main from "../../components/Main";
import setItemFactory from "../../utils/setItemFactory";
import { useHistory } from "react-router-dom";
import AnnouncementForm from "./form";

const AnnouncementUpdateView = ({
  error,
  announcement,
  deleteItem,
  updateItem,
}) => {
  const [announcementObj, setAnnouncementObj] = useState(announcement);

  const setItem = setItemFactory(announcementObj, setAnnouncementObj);

  const history = useHistory();

  return announcement ? (
    <Main
      className="Announcement"
      path={["Annunci", announcement.title]}
      error={error}
      mainActions={[
        {
          label: "Annulla",
          effect: () => {
            history.push("/announcements");
          },
        },
        {
          label: "Salva",
          type: "primary",
          effect: () => {
            updateItem(announcementObj);
          },
        },
      ]}
      secondaryActions={[
        {
          label: "Elimina questo elemento",
          type: "sensitive",
          effect: deleteItem,
        },
      ]}
    >
      <AnnouncementForm announcement={announcement} setItem={setItem} />
    </Main>
  ) : null;
};

export default AnnouncementUpdateView;
