import { useContext, useEffect, useState } from "react";
import UserContext from "./contexts/UserContext";
import SessionStore from "./stores/SessionStore";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./routes/Login";
import ResetPasswordRequest from "./routes/ResetPasswordRequest";
import ResetPassword from "./routes/ResetPassword";
import Logout from "./routes/Logout";
import HeaderNav from "./components/HeaderNav";
import Footer from "./components/Footer";
import "./App.scss";
import "./assets/normalize.css";

import Homepage from "./routes/Homepage";

import HierarchyItems from "./routes/HierarchyItems";
import HierarchyItemUpdate from "./routes/HierarchyItem/update";
import HierarchyItemCreate from "./routes/HierarchyItem/create";

import Labels from "./routes/Labels";
import LabelCreate from "./routes/Label/create";
import LabelUpdate from "./routes/Label/update";

import Features from "./routes/Features";
import FeatureCreate from "./routes/Feature/create";
import FeatureUpdate from "./routes/Feature/update";

import Announcements from "./routes/Announcements";
import AnnouncementCreate from "./routes/Announcement/create";
import AnnouncementUpdate from "./routes/Announcement/update";

import OnboardingSteps from "./routes/OnboardingSteps";
import OnboardingStepCreate from "./routes/OnboardingStep/create";
import OnboardingStepUpdate from "./routes/OnboardingStep/update";

import FrequentQuestions from "./routes/FrequentQuestions";
import FrequentQuestionCreate from "./routes/FrequentQuestion/create";
import FrequentQuestionUpdate from "./routes/FrequentQuestion/update";

import LegalNotes from "./routes/LegalNotes";
import LegalNoteCreate from "./routes/LegalNote/create";
import LegalNoteUpdate from "./routes/LegalNote/update";

import AdminUsers from "./routes/AdminUsers";
import AdminUserCreate from "./routes/AdminUser/create";
import AdminUserUpdate from "./routes/AdminUser/update";

import DirectionTemplates from "./routes/DirectionTemplates";
import DirectionTemplateCreate from "./routes/DirectionTemplate/create";
import DirectionTemplateUpdate from "./routes/DirectionTemplate/update";

import Spaces from "./routes/Spaces";
import SpaceCreate from "./routes/Space/create";
import SpaceUpdate from "./routes/Space/update";
import DirectionUpdate from "./routes/Space/directionUpdate";

import Backups from "./routes/Backups";
import ApiDocs from "./routes/ApiDocs";

const App = () => {
  const [user, setUser] = useState({});

  useEffect(() => {
    (async () => {
      await SessionStore.load(setUser);
    })();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <TopLevelRender />
    </UserContext.Provider>
  );
};

const TopLevelRender = () => {
  const { user } = useContext(UserContext);
  const isNotLogged = !Object.keys(user).length;

  if (isNotLogged) {
    return (
      <Router>
        <Switch>
          <Route
            path="/resetPasswordRequest"
            component={ResetPasswordRequest}
          />
          <Route path="/resetPassword" component={ResetPassword} />
          <Route path="/" component={Login} />
        </Switch>
      </Router>
    );
  } else {
    return (
      <Router>
        <Switch>
          <Route
            path="/resetPasswordRequest"
            component={ResetPasswordRequest}
          />
          <Route path="/resetPassword" component={ResetPassword} />
          <Route path="/logout" component={Logout} />
          <Route path="/">
            <HeaderNav />

            <Switch>
              <Route exact path="/">
                <Homepage />
              </Route>

              <Route exact path="/hierarchyItems">
                <HierarchyItems />
              </Route>
              <Route path="/hierarchyItems/new">
                <HierarchyItemCreate />
              </Route>
              <Route path="/hierarchyItems/:id">
                <HierarchyItemUpdate />
              </Route>

              <Route exact path="/labels">
                <Labels />
              </Route>
              <Route path="/labels/new">
                <LabelCreate />
              </Route>
              <Route path="/labels/:id">
                <LabelUpdate />
              </Route>

              <Route exact path="/features">
                <Features />
              </Route>
              <Route path="/features/new">
                <FeatureCreate />
              </Route>
              <Route path="/features/:id">
                <FeatureUpdate />
              </Route>

              <Route exact path="/announcements">
                <Announcements />
              </Route>
              <Route path="/announcements/new">
                <AnnouncementCreate />
              </Route>
              <Route path="/announcements/:id">
                <AnnouncementUpdate />
              </Route>

              <Route exact path="/onboardingSteps">
                <OnboardingSteps />
              </Route>
              <Route path="/onboardingSteps/new">
                <OnboardingStepCreate />
              </Route>
              <Route path="/onboardingSteps/:id">
                <OnboardingStepUpdate />
              </Route>

              <Route exact path="/frequentQuestions">
                <FrequentQuestions />
              </Route>
              <Route path="/frequentQuestions/new">
                <FrequentQuestionCreate />
              </Route>
              <Route path="/frequentQuestions/:id">
                <FrequentQuestionUpdate />
              </Route>

              <Route exact path="/legalNotes">
                <LegalNotes />
              </Route>
              <Route path="/legalNotes/new">
                <LegalNoteCreate />
              </Route>
              <Route path="/legalNotes/:id">
                <LegalNoteUpdate />
              </Route>

              <Route exact path="/adminUsers">
                <AdminUsers />
              </Route>
              <Route path="/adminUsers/new">
                <AdminUserCreate />
              </Route>
              <Route path="/adminUsers/:id">
                <AdminUserUpdate />
              </Route>

              <Route exact path="/directionTemplates">
                <DirectionTemplates />
              </Route>
              <Route path="/directionTemplates/new">
                <DirectionTemplateCreate />
              </Route>
              <Route path="/directionTemplates/:id">
                <DirectionTemplateUpdate />
              </Route>

              <Route exact path="/spaces">
                <Spaces />
              </Route>
              <Route path="/spaces/new">
                <SpaceCreate />
              </Route>
              <Route exact path="/spaces/:id">
                <SpaceUpdate />
              </Route>
              <Route exact path="/spaces/:id/direction">
                <DirectionUpdate />
              </Route>

              <Route exact path="/backups">
                <Backups />
              </Route>

              <Route exact path="/apidocs">
                <ApiDocs />
              </Route>
            </Switch>
            <Footer />
          </Route>
        </Switch>
      </Router>
    );
  }
};

export default App;
