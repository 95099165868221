import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import apiClient from "../../api";
import Popup from "../../components/Popup";
import SpaceUpdateView from "./updateView";

const SpaceUpdate = () => {
  const [error, setError] = useState(false);
  const [space, setSpace] = useState();
  const [popupData, setPopupData] = useState(false);
  const { id } = useParams();

  const [spaceList, setSpaceList] = useState();
  const [hierarchyItemList, setHierarchyItemList] = useState();
  const [labelList, setLabelList] = useState();
  const [featureList, setFeatureList] = useState();

  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const space = await apiClient["/spaces/{spaceId}"].get({
          pathParams: { spaceId: id },
        });

        //Dumbing down the structure for the form component
        space.parent = space?.parent?._id;
        space.hierarchyItem = space?.hierarchyItem?._id;
        space.labels = space.labels
          ? space.labels.map((label) => label._id)
          : [];
        space.features = space.features
          ? space.features.map((item) => {
              if (item.quantity) {
                return { feature: item.feature._id, quantity: item.quantity };
              } else {
                return { feature: item.feature._id };
              }
            })
          : [];

        setSpace(space);
      } catch (error) {
        setError(error.details);
      }
    })();
  }, [id]);

  useEffect(() => {
    (async () => {
      try {
        let [hierarchyItemList, spaceList, labelList, featureList] =
          await Promise.all([
            apiClient["/hierarchyItems"].get({
              queryParams: { flat: true },
            }),
            apiClient["/spaces"].get(),
            apiClient["/labels"].get(),
            apiClient["/features"].get(),
          ]);

        spaceList = spaceList.map((space) => {
          const newObject = { ...space };
          newObject.pathString =
            space.path.length > 1
              ? space.path.reduce((prev, curr, index) => {
                  if (index < space.path.length - 2) {
                    return prev + curr.displayName + " > ";
                  } else if (index < space.path.length - 1) {
                    return prev + curr.displayName;
                  } else {
                    return prev;
                  }
                }, "")
              : "";
          return newObject;
        });

        setHierarchyItemList(hierarchyItemList);
        setSpaceList(spaceList);
        setLabelList(labelList);
        setFeatureList(featureList);
      } catch (error) {
        setError(error.details);
      }
    })();
  }, []);

  const updateItem = async (
    { parent, hierarchyItem, labels, features, ...data },
    goto = "home"
  ) => {
    data = {
      ...data,
      hierarchyItem: { _id: hierarchyItem },
      labels: labels
        ? labels.map((label) => {
            return { _id: label };
          })
        : [],
      features: features
        ? features.map((feature) => {
            if (feature.quantity) {
              return {
                feature: { _id: feature.feature },
                quantity: feature.quantity,
              };
            } else {
              return {
                feature: { _id: feature.feature },
              };
            }
          })
        : [],
    };

    if (parent) {
      data.parent = { _id: parent };
    }

    delete data._id;
    delete data.path;

    try {
      const updatedObj = await apiClient["/spaces/{spaceId}"].put({
        pathParams: { spaceId: id },
        body: data,
      });
      history.push(
        goto === "home" ? "/spaces" : "/spaces/" + updatedObj._id + "/direction"
      );
    } catch (error) {
      console.log(error);
      setError(error.details);
    }
  };

  const deleteItem = async () => {
    setPopupData({
      title: "Stai per eliminare l'elemento",
      message: "Questa è un'operazione che non potrai più annullare!",
      actions: [
        {
          label: "Annulla",
          effect: "close",
        },
        {
          label: "Elimina",
          type: "sensitive",
          effect: async () => {
            try {
              await apiClient["/spaces/{spaceId}"].delete({
                pathParams: { spaceId: id },
              });
              history.push("/spaces");
            } catch (error) {
              setError(error.details);
            }
          },
        },
      ],
    });
  };

  return space ? (
    <>
      <SpaceUpdateView
        space={space}
        error={error}
        updateItem={updateItem}
        deleteItem={deleteItem}
        hierarchyItemList={hierarchyItemList}
        spaceList={spaceList}
        labelList={labelList}
        featureList={featureList}
      />
      {popupData && <Popup popupData={popupData} setPopupData={setPopupData} />}
    </>
  ) : null;
};

export default SpaceUpdate;
