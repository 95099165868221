import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import apiClient from "../../api";
import DirectionUpdateView from "./directionUpdateView";

const DirectionUpdate = () => {
  const [space, setSpace] = useState();
  const [spaceParent, setSpaceParent] = useState();
  const [error, setError] = useState(false);

  const [setupType, setSetupType] = useState();
  const [directions, setDirections] = useState();

  const [directionTemplateList, setDirectionTemplateList] = useState();

  const { id } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const [space, directionTemplateList] = await Promise.all([
          apiClient["/spaces/{spaceId}"].get({
            pathParams: { spaceId: id },
          }),
          apiClient["/directionTemplates"].get(),
        ]);
        let spaceParent;
        if (space.parent) {
          spaceParent = await apiClient["/spaces/{spaceId}"].get({
            pathParams: { spaceId: space?.parent?._id },
          });
        }
        setDirectionTemplateList(directionTemplateList);
        setSpaceParent(spaceParent);
        setSpace(space);
      } catch (error) {
        setError(error.details);
      }
    })();
  }, [id]);

  useEffect(() => {
    (async () => {
      if (space) {
        try {
          if (!spaceParent && (!space.entrances || !space?.entrances.length)) {
            setSetupType("NOPARENT_NOENTRANCES");

            const direction = await apiClient[
              `/spaces/{spaceId}/direction`
            ].get({
              pathParams: { spaceId: space._id },
            });
            setDirections([direction]);
          }

          if (!spaceParent && space.entrances && space.entrances.length) {
            setSetupType("NOPARENT_ENTRANCES");

            const directions = await Promise.all(
              space.entrances.map((entrance) => {
                return apiClient[
                  `/spaces/{spaceId}/direction/{entranceId}`
                ].get({
                  pathParams: { spaceId: space._id, entranceId: entrance._id },
                });
              })
            );
            setDirections(directions);
          }

          if (
            spaceParent &&
            (!spaceParent.entrances || !spaceParent?.entrances.length)
          ) {
            setSetupType("PARENT_PARENTNOENTRANCES");

            const direction = await apiClient[
              `/spaces/{spaceId}/direction`
            ].get({
              pathParams: { spaceId: space._id },
            });
            setDirections([direction]);
          }

          if (
            spaceParent &&
            spaceParent.entrances &&
            spaceParent.entrances.length
          ) {
            setSetupType("PARENT_PARENTENTRANCES");

            const directions = await Promise.all(
              spaceParent.entrances.map((entrance) => {
                return apiClient[
                  `/spaces/{spaceId}/direction/{entranceId}`
                ].get({
                  pathParams: { spaceId: space._id, entranceId: entrance._id },
                });
              })
            );
            setDirections(directions);
          }
        } catch (error) {
          console.log(error);
          setError(error.details);
        }
      }
    })();
  }, [space, spaceParent]);

  const history = useHistory();

  const updateDirections = async (directions) => {
    try {
      await Promise.all(
        Object.keys(directions).map((entranceId) => {
          if (entranceId === "common") {
            return apiClient[`/spaces/{spaceId}/direction`].put({
              pathParams: { spaceId: space._id },
              body: { steps: directions[entranceId] },
            });
          } else {
            return apiClient[`/spaces/{spaceId}/direction/{entranceId}`].put({
              pathParams: { spaceId: space._id, entranceId: entranceId },
              body: { steps: directions[entranceId] },
            });
          }
        })
      );

      history.push("/spaces");
    } catch (error) {
      console.log(error);
      setError(error.details);
    }
  };

  return directions ? (
    <>
      <DirectionUpdateView
        space={space}
        spaceParent={spaceParent}
        error={error}
        setupType={setupType}
        directions={directions}
        updateDirections={updateDirections}
        directionTemplateList={directionTemplateList}
      />
    </>
  ) : null;
};

export default DirectionUpdate;
