import { useContext, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import UserContext from "../../contexts/UserContext";
import "./index.scss";
import userIcon from "./user.svg";
import logo from "./admin-logo.svg";
import navItems from "../../config/navitems";

const HeaderNav = () => {
  const { user } = useContext(UserContext);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <>
      <header>
        <button
          id="menuOpen"
          onClick={() => {
            setMenuIsOpen(true);
          }}
        ></button>
        <Link to="/">
          <img src={logo} alt="UniSR Maps Admin Area" />
        </Link>
      </header>

      <div
        onClick={() => {
          setMenuIsOpen(false);
        }}
        className={`backdrop ${menuIsOpen ? "menu-open" : "menu-closed"}`}
      ></div>

      <nav className={menuIsOpen ? "menu-open" : "menu-closed"}>
        <button
          id="menuClose"
          onClick={() => {
            setMenuIsOpen(false);
          }}
        ></button>
        <div id="mainNavigation">
          {navItems.map((navGroup, index) => {
            return (
              <ul className="navGroup" key={index}>
                {navGroup
                  .filter((navItem) => navItem[2].includes(user.role))
                  .map((navItem) => {
                    return (
                      <li key={navItem[1]}>
                        <NavLink
                          onClick={() => {
                            setMenuIsOpen(false);
                          }}
                          activeClassName="is-active"
                          to={navItem[1]}
                          exact={
                            navItem[3]
                              ? navItem[3].exact
                                ? true
                                : false
                              : false
                          }
                        >
                          {navItem[0]}
                        </NavLink>
                      </li>
                    );
                  })}
              </ul>
            );
          })}
        </div>
        <div id="userDetails">
          <div id="userBadge">
            <img className="image" src={userIcon} alt={user.name} />
            <div className="text">
              <span className="name">{user.name}</span>
              <span className="role">{user.role}</span>
            </div>
          </div>
          <div className="actions">
            <Link to="/logout">Logout</Link>
            <Link to="/resetPasswordRequest">Reset Password</Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default HeaderNav;
