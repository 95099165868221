import "./index.scss";

const List = ({ items, children: childFunction }) => {
  return items ? (
    <ul className="List">
      {typeof childFunction === "function"
        ? items.map((item) => {
            return childFunction(item);
          })
        : null}
    </ul>
  ) : null;
};

export default List;
