import TextInput from "../../components/TextInput";
import FileInput from "../../components/FileInput";
import ColContainer from "../../components/ColContainer";
import Col from "../../components/Col";
import { API_URL } from "../../config/api";
import apiClient from "../../api";
import langFields from "../../utils/langFields";

const FeatureForm = ({ setItem, feature }) => {
  return (
    <>
      <ColContainer>
        {langFields((code, label) => {
          return (
            <Col type="1-3" key={code}>
              <TextInput
                id={`singularDisplayName${code}`}
                label={`Nome singolare${label}`}
                hint={`Il nome di questo elemento al singolare${label}`}
                initialValue={feature[`singularDisplayName${code}`]}
                onChange={setItem}
              />
            </Col>
          );
        })}
      </ColContainer>
      <ColContainer>
        {langFields((code, label) => {
          return (
            <Col type="1-3" key={code}>
              <TextInput
                id={`pluralDisplayName${code}`}
                label={`Nome plurale${label}`}
                hint={`Il nome di questo elemento al plurale${label}`}
                initialValue={feature[`pluralDisplayName${code}`]}
                onChange={setItem}
              />
            </Col>
          );
        })}
      </ColContainer>
      <ColContainer>
        <Col type="1-3">
          <FileInput
            id="iconUrl"
            label="Icona"
            hint="Preferibile SVG 100px x 100px"
            initialValue={feature.iconUrl}
            onChange={setItem}
            FileRenderer={({ fileId }) => {
              return (
                <img
                  style={{ height: "100px" }}
                  alt={fileId}
                  src={`${API_URL}/mediaElements/${fileId}`}
                />
              );
            }}
            uploadHandler={async (file) => {
              const formData = new FormData();
              formData.append("file", file);

              const fileId = await apiClient["/mediaElements"].post({
                body: formData,
              });
              return fileId;
            }}
          />
        </Col>
      </ColContainer>
    </>
  );
};

export default FeatureForm;
