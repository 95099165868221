import Message from "../../components/Message";
import TextInput from "../../components/TextInput";
import ToggleInput from "../../components/ToggleInput";
import MultipleInput from "../../components/MultipleInput";
import SelectInput from "../../components/SelectInput";
import RichtextInput from "../../components/RichtextInput";
import ColContainer from "../../components/ColContainer";
import Col from "../../components/Col";
import FileInput from "../../components/FileInput";
import { API_URL } from "../../config/api";
import apiClient from "../../api";
import { useEffect, useState } from "react";

import UserContext from "../../contexts/UserContext";
import { useContext } from "react";
import langFields from "../../utils/langFields";

const SpaceForm = ({
  setItem,
  space,
  spaceList,
  hierarchyItemList,
  labelList,
  featureList,
}) => {
  const [currentHierarchyItemId, setCurrentHierarchyItemId] = useState(
    space.hierarchyItem
  );
  const [currentHierarchyItem, setCurrentHierarchyItem] = useState();

  useEffect(() => {
    if (currentHierarchyItemId && hierarchyItemList) {
      setCurrentHierarchyItem(
        hierarchyItemList.find((item) => item._id === currentHierarchyItemId)
      );
    }
  }, [hierarchyItemList, currentHierarchyItemId]);

  const { user } = useContext(UserContext);
  const notEditorial = user.role !== "EDITORIAL";

  return (
    <>
      {!notEditorial && (
        <Message
          title="Editing limitato"
          message="La tua utenza è di tipo EDITORIAL, quindi puoi modificare solo la descrizione, i pulsanti e la fotografia associati a questo spazio."
        />
      )}
      {notEditorial && (
        <>
          <ColContainer>
            {langFields((code, label) => {
              return (
                <Col type="1-3" key={code}>
                  <TextInput
                    id={`displayName${code}`}
                    label={`Nome${label}`}
                    hint={`Il nome ufficiale dello spazio. Utilizza un nome autosufficiente (es. 'Campus Rossi', non 'Rossi'; 'Aula 702', non '702)${label}`}
                    initialValue={space[`displayName${code}`]}
                    onChange={setItem}
                  />
                </Col>
              );
            })}
          </ColContainer>
          <ColContainer>
            <Col type="1-3">
              <TextInput
                id="weight"
                label="Peso"
                initialValue={space.weight}
                onChange={setItem}
                parse="number"
                hint="Se si desidera privilegiare questo spazio nella lista, fornire un numero più alto"
              />
            </Col>
          </ColContainer>

          <ColContainer>
            <Col type="1-3">
              <SelectInput
                id="parent"
                label="Spazio genitore"
                hint="Indicare di chi è figlio questo spazio"
                initialValue={space.parent}
                onChange={setItem}
                items={spaceList}
                valuePropertyName="_id"
                displayPropertyName="displayName"
                subtitlePropertyName="pathString"
                nullable
                searchable
              />
            </Col>
            <Col type="1-3">
              <SelectInput
                id="hierarchyItem"
                label="Posizione nella gerarchia"
                initialValue={space.hierarchyItem}
                onChange={(newValue, property) => {
                  setItem(newValue, property);
                  setCurrentHierarchyItemId(newValue);
                }}
                items={hierarchyItemList}
                valuePropertyName="_id"
                displayPropertyName="singularDisplayName"
                nullable
              />
            </Col>
            <Col type="1-3">
              <ColContainer>
                <Col type="1-2">
                  <TextInput
                    id="lat"
                    label="Latitudine"
                    initialValue={space.lat}
                    onChange={setItem}
                    parse="number"
                  />
                </Col>
                <Col type="1-2">
                  <TextInput
                    id="long"
                    label="Longitudine"
                    initialValue={space.long}
                    onChange={setItem}
                    parse="number"
                  />
                </Col>
              </ColContainer>
            </Col>
          </ColContainer>
          <ColContainer>
            <Col type="1-3">
              <MultipleInput
                id="alternativeNames"
                label="Nomi alternativi"
                hint="Elenca qui alcuni nomi alternativi di questo spazio"
                initialValue={space.alternativeNames}
                onChange={setItem}
                simpleItem
              >
                {(item, changeHandler) => {
                  return (
                    <>
                      <ColContainer>
                        <Col type="1-1">
                          <TextInput
                            initialValue={item}
                            onChange={changeHandler}
                          />
                        </Col>
                      </ColContainer>
                    </>
                  );
                }}
              </MultipleInput>
            </Col>
            <Col type="1-3">
              <MultipleInput
                id="labels"
                label="Etichette"
                hint="Associa questo spazio ad una o più etichette"
                initialValue={space.labels}
                onChange={setItem}
                simpleItem
              >
                {(item, changeHandler) => {
                  return (
                    <>
                      <ColContainer>
                        <Col type="1-1">
                          <SelectInput
                            initialValue={item}
                            onChange={changeHandler}
                            items={labelList}
                            valuePropertyName="_id"
                            displayPropertyName="singularDisplayName"
                          />
                        </Col>
                      </ColContainer>
                    </>
                  );
                }}
              </MultipleInput>
            </Col>
          </ColContainer>
          <ColContainer>
            <Col type="1-1">
              <MultipleInput
                id="features"
                label="Caratteristiche"
                hint="Elenca qui le caratteristiche dello spazio. Se si tratta di una caratteristica numerabile, inseriscine anche la quantità"
                initialValue={space.features}
                onChange={setItem}
              >
                {(item, changeHandler) => {
                  return (
                    <>
                      <ColContainer>
                        <Col type="1-3">
                          <SelectInput
                            label="Tipo di caratteristica"
                            id="feature"
                            initialValue={item.feature}
                            onChange={changeHandler}
                            items={featureList}
                            valuePropertyName="_id"
                            displayPropertyName="singularDisplayName"
                          />
                        </Col>
                        <Col type="1-3">
                          <TextInput
                            id="quantity"
                            label="Quantità"
                            initialValue={item.quantity}
                            onChange={changeHandler}
                            parse="number"
                          />
                        </Col>
                      </ColContainer>
                    </>
                  );
                }}
              </MultipleInput>
            </Col>
          </ColContainer>
        </>
      )}
      <ColContainer>
        <Col type="1-3">
          <FileInput
            id="photoUrl"
            label="Fotografia"
            hint="Preferibile JPEG 1920px x 1080px"
            initialValue={space.photoUrl}
            onChange={setItem}
            FileRenderer={({ fileId }) => {
              return (
                <img
                  style={{ height: "100px" }}
                  alt={fileId}
                  src={`${API_URL}/mediaElements/${fileId}`}
                />
              );
            }}
            uploadHandler={async (file) => {
              const formData = new FormData();
              formData.append("file", file);

              const fileId = await apiClient["/mediaElements"].post({
                body: formData,
              });
              return fileId;
            }}
          />
        </Col>
      </ColContainer>
      <ColContainer>
        {langFields((code, label) => {
          return (
            <Col type="2-3" key={code}>
              <RichtextInput
                id={`description${code}`}
                label={`Descrizione${label}`}
                hint={`Un breve testo che descrive lo spazio${label}`}
                initialValue={space[`description${code}`]}
                onChange={setItem}
              />
            </Col>
          );
        })}
      </ColContainer>
      <ColContainer>
        <Col type="1-1">
          <MultipleInput
            id="buttons"
            label="Pulsanti"
            hint="È possibile aggiungere pulsanti, fornendo un link a cui poter puntare"
            initialValue={space.buttons}
            onChange={setItem}
          >
            {(item, changeHandler) => {
              return (
                <>
                  <ColContainer>
                    {langFields((code, label) => {
                      return (
                        <Col type="1-3" key={code}>
                          <TextInput
                            id={`text${code}`}
                            label={`Testo del pulsante${label}`}
                            initialValue={item[`text${code}`]}
                            onChange={changeHandler}
                          />
                        </Col>
                      );
                    })}
                  </ColContainer>
                  <ColContainer>
                    {langFields((code, label) => {
                      return (
                        <Col type="1-3" key={code}>
                          <TextInput
                            id={`url${code}`}
                            label={`URL del pulsante${label}`}
                            hint={`Inserire un URL completo (es. https://www.google.com)${label}`}
                            initialValue={item[`url${code}`]}
                            onChange={changeHandler}
                          />
                        </Col>
                      );
                    })}
                  </ColContainer>
                </>
              );
            }}
          </MultipleInput>
        </Col>
      </ColContainer>
      {notEditorial &&
      currentHierarchyItem &&
      !currentHierarchyItem.parentId ? (
        <>
          <ColContainer>
            <Col type="1-1">
              <h2>Ingressi</h2>
              <Message
                title=""
                message={`Questo spazio si trova al livello gerarchico più alto (${currentHierarchyItem?.singularDisplayName}). Per questo, puoi specificare diversi ingressi.`}
              />
            </Col>
          </ColContainer>
          <ColContainer>
            <Col type="1-1">
              <MultipleInput
                id="entrances"
                initialValue={space.entrances}
                onChange={setItem}
              >
                {(item, changeHandler) => {
                  return (
                    <>
                      <ColContainer>
                        {langFields((code, label) => {
                          return (
                            <Col type="1-3" key={code}>
                              <TextInput
                                id={`displayName${code}`}
                                label={`Nome dell'entrata${label}`}
                                initialValue={item[`displayName${code}`]}
                                onChange={changeHandler}
                              />
                            </Col>
                          );
                        })}
                        <Col type="1-3">
                          <ToggleInput
                            id="isPrimary"
                            label="Entrata principale"
                            initialValue={item?.isPrimary}
                            onChange={changeHandler}
                          />
                        </Col>
                      </ColContainer>
                    </>
                  );
                }}
              </MultipleInput>
            </Col>
          </ColContainer>
        </>
      ) : null}
    </>
  );
};

export default SpaceForm;
