import List from "../../components/List";
import Main from "../../components/Main";
import Message from "../../components/Message";
import Row from "../../components/Row";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useHistory } from "react-router-dom";
import "./index.scss";

const OnboardingStepsView = ({
  error,
  onboardingSteps,
  deleteItem,
  orderHandler,
}) => {
  const history = useHistory();

  return (
    <Main
      className="OnboardingSteps"
      path={["Step di onboarding"]}
      isParent
      error={error}
      mainActions={[
        {
          label: "Crea nuovo",
          type: "primary",
          effect: () => {
            history.push("/onboardingSteps/new");
          },
        },
      ]}
    >
      <Message
        title=""
        message={`
          Gli step di onboarding vengono mostrati all'utente alla prima visita dell'app.
      `}
      />
      <h2>Tutti gli step di onboarding</h2>

      <List items={onboardingSteps}>
        {(item) => {
          return (
            <Row
              to={`/onboardingSteps/${item._id}`}
              key={item._id}
              title={item.title}
              orderable
              orderHandler={orderHandler}
              id={item._id}
              actions={[
                {
                  title: "Modifica",
                  icon: EditIcon,
                  to: `/onboardingSteps/${item._id}`,
                },
                {
                  title: "Elimina",
                  icon: DeleteIcon,
                  callback: () => {
                    deleteItem(item._id);
                  },
                },
              ]}
            />
          );
        }}
      </List>
    </Main>
  );
};
export default OnboardingStepsView;
