import { useState } from "react";
import Main from "../../components/Main";
import setItemFactory from "../../utils/setItemFactory";
import { useHistory } from "react-router-dom";
import HierarchyItemForm from "./form";

const HierarchyItemUpdateView = ({
  error,
  hierarchyItem,
  hierarchyItemList,
  deleteItem,
  updateItem,
}) => {
  const [hierarchyItemObj, setHierarchyItemObj] = useState(hierarchyItem);

  const setItem = setItemFactory(hierarchyItemObj, setHierarchyItemObj);

  const history = useHistory();

  return hierarchyItem ? (
    <Main
      className="HierarchyItem"
      path={["Gerarchia", hierarchyItem.singularDisplayName]}
      error={error}
      mainActions={[
        {
          label: "Annulla",
          effect: () => {
            history.push("/hierarchyItems");
          },
        },
        {
          label: "Salva",
          type: "primary",
          effect: () => {
            updateItem(hierarchyItemObj);
          },
        },
      ]}
      secondaryActions={[
        {
          label: "Elimina questo elemento",
          type: "sensitive",
          effect: deleteItem,
        },
      ]}
    >
      <HierarchyItemForm
        hierarchyItem={hierarchyItem}
        hierarchyItemList={hierarchyItemList}
        setItem={setItem}
      />
    </Main>
  ) : null;
};

export default HierarchyItemUpdateView;
