import { useState } from "react";
import Main from "../../components/Main";
import setItemFactory from "../../utils/setItemFactory";
import { useHistory } from "react-router-dom";
import AdminUserForm from "./form";

const AdminUserUpdateView = ({ error, adminUser, deleteItem, updateItem }) => {
  const [adminUserObj, setAdminUserObj] = useState(adminUser);

  const setItem = setItemFactory(adminUserObj, setAdminUserObj);

  const history = useHistory();

  return adminUser ? (
    <Main
      className="AdminUser"
      path={["Utenze di admin", adminUser.name]}
      error={error}
      mainActions={[
        {
          label: "Annulla",
          effect: () => {
            history.push("/adminUsers");
          },
        },
        {
          label: "Salva",
          type: "primary",
          effect: () => {
            updateItem(adminUserObj);
          },
        },
      ]}
      secondaryActions={[
        {
          label: "Elimina questo elemento",
          type: "sensitive",
          effect: deleteItem,
        },
      ]}
    >
      <AdminUserForm adminUser={adminUser} setItem={setItem} />
    </Main>
  ) : null;
};

export default AdminUserUpdateView;
