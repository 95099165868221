import { useState } from "react";
import Main from "../../components/Main";
import setItemFactory from "../../utils/setItemFactory";
import { useHistory } from "react-router-dom";
import SpaceForm from "./form";

const SpaceCreateView = ({
  error,
  space,
  createItem,
  createItemAndSetDirections,
  spaceList,
  hierarchyItemList,
  labelList,
  featureList,
}) => {
  const [spaceObj, setSpaceObj] = useState(space);
  const setItem = setItemFactory(spaceObj, setSpaceObj);

  const history = useHistory();

  return space ? (
    <Main
      className="Space"
      path={["Spazi", "Crea nuovo"]}
      error={error}
      mainActions={[
        {
          label: "Annulla",
          effect: () => {
            history.push("/spaces");
          },
        },
        {
          label: "Salva",
          type: "primary",
          effect: () => {
            createItem(spaceObj);
          },
        },
        {
          label: "Salva e passa alle direzioni",
          type: "primary",
          effect: () => {
            createItem(spaceObj, "directions");
          },
        },
      ]}
    >
      <SpaceForm
        space={space}
        setItem={setItem}
        spaceList={spaceList}
        hierarchyItemList={hierarchyItemList}
        labelList={labelList}
        featureList={featureList}
      />
    </Main>
  ) : null;
};

export default SpaceCreateView;
