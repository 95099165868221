import TextInput from "../../components/TextInput";
import MultipleInput from "../../components/MultipleInput";
import ColContainer from "../../components/ColContainer";
import Col from "../../components/Col";
import langFields from "../../utils/langFields";

const LabelForm = ({ setItem, label }) => {
  return (
    <>
      <ColContainer>
        {langFields((code, langLabel) => {
          return (
            <Col type="1-3" key={code}>
              <TextInput
                id={`singularDisplayName${code}`}
                label={`Nome singolare${langLabel}`}
                hint={`Il nome di questo elemento al singolare${langLabel}`}
                initialValue={label[`singularDisplayName${code}`]}
                onChange={setItem}
              />
            </Col>
          );
        })}
      </ColContainer>
      <ColContainer>
        {langFields((code, langLabel) => {
          return (
            <Col type="1-3" key={code}>
              <TextInput
                id={`pluralDisplayName${code}`}
                label={`Nome plurale${langLabel}`}
                hint={`Il nome di questo elemento al plurale${langLabel}`}
                initialValue={label[`pluralDisplayName${code}`]}
                onChange={setItem}
              />
            </Col>
          );
        })}
      </ColContainer>
      <ColContainer>
        <Col type="1-1">
          <MultipleInput
            id="alternativeNames"
            label="Nomi alternativi"
            hint="Elenca qui una serie di nomi alternativi che gli utenti potrebbero utilizzare al posto del nome principale durante una ricerca"
            initialValue={label.alternativeNames}
            onChange={setItem}
          >
            {(item, changeHandler) => {
              return (
                <>
                  <ColContainer>
                    <Col type="1-3">
                      <TextInput
                        id={`singularAlternativeName`}
                        label={`Nome alternativo singolare`}
                        hint={`Il nome di questo elemento al singolare`}
                        initialValue={item[`singularAlternativeName`]}
                        onChange={changeHandler}
                      />
                    </Col>
                    <Col type="1-3">
                      <TextInput
                        id={`pluralAlternativeName`}
                        label={`Nome alternativo plurale`}
                        hint={`Il nome di questo elemento al plurale`}
                        initialValue={item[`pluralAlternativeName`]}
                        onChange={changeHandler}
                      />
                    </Col>
                  </ColContainer>
                </>
              );
            }}
          </MultipleInput>
        </Col>
      </ColContainer>
    </>
  );
};

export default LabelForm;
