import List from "../../components/List";
import Main from "../../components/Main";
import Message from "../../components/Message";
import Row from "../../components/Row";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useHistory } from "react-router-dom";
import { DateTime } from "luxon";
import "./index.scss";

const AnnouncementsView = ({ error, announcements, deleteItem }) => {
  const history = useHistory();

  return (
    <Main
      className="Announcements"
      path={["Annunci"]}
      isParent
      error={error}
      mainActions={[
        {
          label: "Crea nuovo",
          type: "primary",
          effect: () => {
            history.push("/announcements/new");
          },
        },
      ]}
    >
      <Message
        title=""
        message={`
          Gli annunci, mostrati nell'homescreen dell'app, avvisano gli utenti di variazioni o notizie importanti relative agli spazi.
      `}
      />
      <h2>Tutti gli annunci</h2>

      <List items={announcements}>
        {(item) => {
          return (
            <Row
              to={`/announcements/${item._id}`}
              key={item._id}
              title={item.title}
              actions={[
                {
                  title: "Modifica",
                  icon: EditIcon,
                  to: `/announcements/${item._id}`,
                },
                {
                  title: "Elimina",
                  icon: DeleteIcon,
                  callback: () => {
                    deleteItem(item._id);
                  },
                },
              ]}
            >
              <div className="date">
                {DateTime.fromISO(item.publishDate).toLocaleString(
                  DateTime.DATETIME_SHORT
                )}
                {item.expiryDate
                  ? " > " +
                    DateTime.fromISO(item.expiryDate).toLocaleString(
                      DateTime.DATETIME_SHORT
                    )
                  : null}
              </div>
            </Row>
          );
        }}
      </List>
    </Main>
  );
};
export default AnnouncementsView;
