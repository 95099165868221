import Main from "../../components/Main";
import { useHistory } from "react-router-dom";
import DirectionTemplateForm from "./form";

const DirectionTemplateUpdateView = ({
  error,
  directionTemplate,
  deleteItem,
  updateItem,
}) => {
  /* const [directionTemplateObj, setDirectionTemplateObj] = useState(
    directionTemplate
  );

  const setItem = setItemFactory(directionTemplateObj, setDirectionTemplateObj);
*/

  let directionTemplateObj = directionTemplate;

  const setItem = (newValue, property) => {
    directionTemplateObj[property] = newValue;
  };

  const history = useHistory();

  const updateHandler = () => {
    updateItem(directionTemplateObj);
  };

  return directionTemplate ? (
    <Main
      className="DirectionTemplate"
      path={["Template direzione", directionTemplate.displayName]}
      error={error}
      mainActions={[
        {
          label: "Annulla",
          effect: () => {
            history.push("/directionTemplates");
          },
        },
        {
          label: "Salva",
          type: "primary",
          effect: updateHandler,
        },
      ]}
      secondaryActions={[
        {
          label: "Elimina questo elemento",
          type: "sensitive",
          effect: deleteItem,
        },
      ]}
    >
      <DirectionTemplateForm
        directionTemplate={directionTemplate}
        setItem={setItem}
      />
    </Main>
  ) : null;
};

export default DirectionTemplateUpdateView;
