import { useState } from "react";
import { useHistory } from "react-router-dom";
import apiClient from "../../api";
import Popup from "../../components/Popup";
import DirectionTemplateCreateView from "./createView";

const DirectionTemplateCreate = () => {
  const [error, setError] = useState(false);
  const [popupData, setPopupData] = useState(false);

  const history = useHistory();

  const directionTemplate = {};

  const createItem = async ({ displayName, steps }) => {
    const data = {
      displayName,
      steps,
    };
    try {
      await apiClient["/directionTemplates"].post({
        body: data,
      });
      history.push("/directionTemplates");
    } catch (error) {
      console.log(error);
      setError(error.details);
    }
  };

  return directionTemplate ? (
    <>
      <DirectionTemplateCreateView
        directionTemplate={directionTemplate}
        error={error}
        createItem={createItem}
      />
      {popupData && <Popup popupData={popupData} setPopupData={setPopupData} />}
    </>
  ) : null;
};

export default DirectionTemplateCreate;
