import { useState } from "react";
import Main from "../../components/Main";
import setItemFactory from "../../utils/setItemFactory";
import { useHistory } from "react-router-dom";
import OnboardingStepForm from "./form";

const OnboardingStepUpdateView = ({
  error,
  onboardingStep,
  deleteItem,
  updateItem,
}) => {
  const [onboardingStepObj, setOnboardingStepObj] = useState(onboardingStep);

  const setItem = setItemFactory(onboardingStepObj, setOnboardingStepObj);

  const history = useHistory();

  return onboardingStep ? (
    <Main
      className="OnboardingStep"
      path={["Step di onboarding", onboardingStep.title]}
      error={error}
      mainActions={[
        {
          label: "Annulla",
          effect: () => {
            history.push("/onboardingSteps");
          },
        },
        {
          label: "Salva",
          type: "primary",
          effect: () => {
            updateItem(onboardingStepObj);
          },
        },
      ]}
      secondaryActions={[
        {
          label: "Elimina questo elemento",
          type: "sensitive",
          effect: deleteItem,
        },
      ]}
    >
      <OnboardingStepForm onboardingStep={onboardingStep} setItem={setItem} />
    </Main>
  ) : null;
};

export default OnboardingStepUpdateView;
