import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import apiClient from "../../api";
import Popup from "../../components/Popup";
import LegalNoteUpdateView from "./updateView";

const LegalNoteUpdate = () => {
  const [error, setError] = useState(false);
  const [legalNote, setLegalNote] = useState();
  const [popupData, setPopupData] = useState(false);
  const { id } = useParams();

  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const legalNote = await apiClient["/legalNotes/{legalNoteId}"].get({
          pathParams: { legalNoteId: id },
        });
        setLegalNote(legalNote);
      } catch (error) {
        setError(error.details);
      }
    })();
  }, [id]);

  const updateItem = async (data) => {
    delete data._id;
    try {
      await apiClient["/legalNotes/{legalNoteId}"].put({
        pathParams: { legalNoteId: id },
        body: data,
      });
      history.push("/legalNotes");
    } catch (error) {
      console.log(error);
      setError(error.details);
    }
  };

  const deleteItem = async () => {
    setPopupData({
      title: "Stai per eliminare l'elemento",
      message: "Questa è un'operazione che non potrai più annullare!",
      actions: [
        {
          label: "Annulla",
          effect: "close",
        },
        {
          label: "Elimina",
          type: "sensitive",
          effect: async () => {
            try {
              await apiClient["/legalNotes/{legalNoteId}"].delete({
                pathParams: { legalNoteId: id },
              });
              history.push("/legalNotes");
            } catch (error) {
              setError(error.details);
            }
          },
        },
      ],
    });
  };

  return legalNote ? (
    <>
      <LegalNoteUpdateView
        legalNote={legalNote}
        error={error}
        updateItem={updateItem}
        deleteItem={deleteItem}
      />
      {popupData && <Popup popupData={popupData} setPopupData={setPopupData} />}
    </>
  ) : null;
};

export default LegalNoteUpdate;
