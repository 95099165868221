import { useEffect, useState } from "react";
import apiClient from "../../api";
import Popup from "../../components/Popup";
import AnnouncementsView from "./view";

const Announcements = () => {
  const [error, setError] = useState(false);
  const [announcements, setAnnouncements] = useState();
  const [popupData, setPopupData] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const announcements = await apiClient["/announcements"].get({
          queryParams: { all: true },
        });
        setAnnouncements(announcements);
      } catch (error) {
        setError(error.details);
      }
    })();
  }, []);

  const deleteItem = async (announcementId) => {
    setPopupData({
      title: "Stai per eliminare l'elemento",
      message: "Questa è un'operazione che non potrai più annullare!",
      actions: [
        {
          label: "Annulla",
          effect: "close",
        },
        {
          label: "Elimina",
          type: "sensitive",
          effect: async () => {
            try {
              await apiClient["/announcements/{announcementId}"].delete({
                pathParams: { announcementId },
              });
              const announcements = await apiClient["/announcements"].get();
              setAnnouncements(announcements);
            } catch (error) {
              setError(error.details);
            }
          },
        },
      ],
    });
  };

  return (
    <>
      <AnnouncementsView
        announcements={announcements}
        error={error}
        deleteItem={deleteItem}
      />
      {popupData && <Popup popupData={popupData} setPopupData={setPopupData} />}
    </>
  );
};

export default Announcements;
