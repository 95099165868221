import { useState } from "react";
import Main from "../../components/Main";
import setItemFactory from "../../utils/setItemFactory";
import { useHistory } from "react-router-dom";
import FrequentQuestionForm from "./form";

const FrequentQuestionCreateView = ({
  error,
  frequentQuestion,
  createItem,
}) => {
  const [frequentQuestionObj, setFrequentQuestionObj] = useState(
    frequentQuestion
  );
  const setItem = setItemFactory(frequentQuestionObj, setFrequentQuestionObj);

  const history = useHistory();

  return frequentQuestion ? (
    <Main
      className="FrequentQuestion"
      path={["Domande frequenti", "Crea nuovo"]}
      error={error}
      mainActions={[
        {
          label: "Annulla",
          effect: () => {
            history.push("/frequentQuestions");
          },
        },
        {
          label: "Salva",
          type: "primary",
          effect: () => {
            createItem(frequentQuestionObj);
          },
        },
      ]}
    >
      <FrequentQuestionForm
        frequentQuestion={frequentQuestion}
        setItem={setItem}
      />
    </Main>
  ) : null;
};

export default FrequentQuestionCreateView;
