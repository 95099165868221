import { useState } from "react";
import "./index.scss";

const ToggleInput = ({
  id,
  disabled,
  label,
  hint,
  initialValue,
  error,
  onChange,
}) => {
  onChange = onChange ? onChange : () => {};

  if (!initialValue) initialValue = false;
  const [currentValue, setCurrentValue] = useState(initialValue);

  const changeHandler = () => {
    const newValue = !currentValue;
    setCurrentValue(newValue);
    onChange(newValue, id);
  };

  return (
    <label className="ToggleInput">
      {label && <span className="labelText">{label}</span>}
      <input
        data-custom-id={id}
        disabled={disabled}
        type="checkbox"
        checked={currentValue}
        onChange={changeHandler}
      />
      <span className="toggle"></span>
      {hint && <span className="hintText">{hint}</span>}
    </label>
  );
};

export default ToggleInput;
