import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import apiClient from "../../api";
import Popup from "../../components/Popup";
import FrequentQuestionUpdateView from "./updateView";

const FrequentQuestionUpdate = () => {
  const [error, setError] = useState(false);
  const [frequentQuestion, setFrequentQuestion] = useState();
  const [popupData, setPopupData] = useState(false);
  const { id } = useParams();

  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const frequentQuestion = await apiClient[
          "/frequentQuestions/{frequentQuestionId}"
        ].get({
          pathParams: { frequentQuestionId: id },
        });
        setFrequentQuestion(frequentQuestion);
      } catch (error) {
        setError(error.details);
      }
    })();
  }, [id]);

  const updateItem = async (data) => {
    delete data._id;
    try {
      await apiClient["/frequentQuestions/{frequentQuestionId}"].put({
        pathParams: { frequentQuestionId: id },
        body: data,
      });
      history.push("/frequentQuestions");
    } catch (error) {
      console.log(error);
      setError(error.details);
    }
  };

  const deleteItem = async () => {
    setPopupData({
      title: "Stai per eliminare l'elemento",
      message: "Questa è un'operazione che non potrai più annullare!",
      actions: [
        {
          label: "Annulla",
          effect: "close",
        },
        {
          label: "Elimina",
          type: "sensitive",
          effect: async () => {
            try {
              await apiClient["/frequentQuestions/{frequentQuestionId}"].delete(
                {
                  pathParams: { frequentQuestionId: id },
                }
              );
              history.push("/frequentQuestions");
            } catch (error) {
              setError(error.details);
            }
          },
        },
      ],
    });
  };

  return frequentQuestion ? (
    <>
      <FrequentQuestionUpdateView
        frequentQuestion={frequentQuestion}
        error={error}
        updateItem={updateItem}
        deleteItem={deleteItem}
      />
      {popupData && <Popup popupData={popupData} setPopupData={setPopupData} />}
    </>
  ) : null;
};

export default FrequentQuestionUpdate;
