import { useContext, useState } from "react";
import apiClient from "../../api";
import UserContext from "../../contexts/UserContext";
import SessionStore from "../../stores/SessionStore";
import LoginView from "./view";
import "./index.scss";

const Login = () => {
  const { setUser } = useContext(UserContext);
  const [isError, setIsError] = useState(false);

  const loginAction = async (username, password) => {
    try {
      const sessionResult = await apiClient["/auth/token"].post({
        body: { username, password, grant_type: "password" },
        contentType: "application/x-www-form-urlencoded",
      });
      await SessionStore.set(sessionResult, setUser);
    } catch (error) {
      console.log(error);
      setIsError(true);
      setUser({});
    }
  };

  return <LoginView isError={isError} action={loginAction} />;
};

export default Login;
