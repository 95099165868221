import { useState } from "react";
import { useHistory } from "react-router-dom";
import apiClient from "../../api";
import Popup from "../../components/Popup";
import FeatureCreateView from "./createView";

const FeatureCreate = () => {
  const [error, setError] = useState(false);
  const [popupData, setPopupData] = useState(false);

  const history = useHistory();

  const feature = {};

  const createItem = async (data) => {
    delete data._id;
    try {
      await apiClient["/features"].post({
        body: data,
      });
      history.push("/features");
    } catch (error) {
      console.log(error);
      setError(error.details);
    }
  };

  return feature ? (
    <>
      <FeatureCreateView
        feature={feature}
        error={error}
        createItem={createItem}
      />
      {popupData && <Popup popupData={popupData} setPopupData={setPopupData} />}
    </>
  ) : null;
};

export default FeatureCreate;
