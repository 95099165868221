import MUIRichTextEditor from "mui-rte";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import "./index.scss";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

const RichtextInput = ({
  id,
  disabled,
  label,
  hint,
  initialValue,
  error,
  onChange,
}) => {
  onChange = onChange ? onChange : () => {};

  if (!initialValue) initialValue = "";

  const [defaultValue, setDefaultValue] = useState();

  useEffect(() => {
    const contentHTML = convertFromHTML(initialValue);
    const state = ContentState.createFromBlockArray(
      contentHTML.contentBlocks,
      contentHTML.entityMap
    );
    setDefaultValue(JSON.stringify(convertToRaw(state)));
  }, [initialValue]);

  let currentValue;

  return (
    <div className="RichtextInput">
      {label && <span className="labelText">{label}</span>}
      <MUIRichTextEditor
        controls={[
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "link",
          "numberList",
          "bulletList",
          "undo",
          "redo",
          "clear",
        ]}
        defaultValue={defaultValue}
        onChange={(state) => {
          const html = stateToHTML(state.getCurrentContent());
          currentValue = html;
        }}
        onBlur={() => {
          onChange(currentValue, id);
        }}
      />
      {hint && <span className="hintText">{hint}</span>}
    </div>
  );
};

export default RichtextInput;
