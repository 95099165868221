import { useState } from "react";
import apiClient from "../../api";
import ResetPasswordRequestView from "./view";

const ResetPasswordRequest = () => {
  const [isDone, setIsDone] = useState(false);
  const [isError, setIsError] = useState(false);

  const resetRequestAction = async (email) => {
    try {
      await apiClient["/auth/resetRequest"].post({
        body: { email },
      });
      setIsDone(true);
    } catch (error) {
      console.log(error);
      setIsError(true);
    }
  };

  return (
    <ResetPasswordRequestView
      isDone={isDone}
      isError={isError}
      action={resetRequestAction}
    />
  );
};

export default ResetPasswordRequest;
