import { useState } from "react";
import Main from "../../components/Main";
import setItemFactory from "../../utils/setItemFactory";
import { useHistory } from "react-router-dom";
import HierarchyItemForm from "./form";

const HierarchyItemCreateView = ({
  error,
  hierarchyItem,
  hierarchyItemList,
  createItem,
}) => {
  const [hierarchyItemObj, setHierarchyItemObj] = useState(hierarchyItem);

  const setItem = setItemFactory(hierarchyItemObj, setHierarchyItemObj);

  const history = useHistory();

  return hierarchyItem ? (
    <Main
      className="HierarchyItem"
      path={["Gerarchia", "Crea nuovo"]}
      error={error}
      mainActions={[
        {
          label: "Annulla",
          effect: () => {
            history.push("/hierarchyItems");
          },
        },
        {
          label: "Salva",
          type: "primary",
          effect: () => {
            createItem(hierarchyItemObj);
          },
        },
      ]}
    >
      <HierarchyItemForm
        hierarchyItem={hierarchyItem}
        hierarchyItemList={hierarchyItemList}
        setItem={setItem}
      />
    </Main>
  ) : null;
};

export default HierarchyItemCreateView;
