import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import apiClient from "../../api";
import Popup from "../../components/Popup";
import SpaceCreateView from "./createView";

const SpaceCreate = () => {
  const [error, setError] = useState(false);
  const [popupData, setPopupData] = useState(false);

  const [spaceList, setSpaceList] = useState();
  const [hierarchyItemList, setHierarchyItemList] = useState();
  const [labelList, setLabelList] = useState();
  const [featureList, setFeatureList] = useState();

  const history = useHistory();

  const space = {};

  useEffect(() => {
    (async () => {
      try {
        let [hierarchyItemList, spaceList, labelList, featureList] =
          await Promise.all([
            apiClient["/hierarchyItems"].get({
              queryParams: { flat: true },
            }),
            apiClient["/spaces"].get(),
            apiClient["/labels"].get(),
            apiClient["/features"].get(),
          ]);

        spaceList = spaceList.map((space) => {
          const newObject = { ...space };
          newObject.pathString =
            space.path.length > 1
              ? space.path.reduce((prev, curr, index) => {
                  console.log(index, space.path.length - 1);
                  if (index < space.path.length - 2) {
                    return prev + curr.displayName + " > ";
                  } else if (index < space.path.length - 1) {
                    return prev + curr.displayName;
                  } else {
                    return prev;
                  }
                }, "")
              : "";
          return newObject;
        });

        setHierarchyItemList(hierarchyItemList);
        setSpaceList(spaceList);
        setLabelList(labelList);
        setFeatureList(featureList);
      } catch (error) {
        setError(error.details);
      }
    })();
  }, []);

  const createItem = async (
    { parent, hierarchyItem, labels, features, ...data },
    goto = "home"
  ) => {
    data = {
      hierarchyItem: { _id: hierarchyItem },
      labels: labels
        ? labels.map((label) => {
            return { _id: label };
          })
        : [],
      features: features
        ? features.map((feature) => {
            if (feature.quantity) {
              return {
                feature: { _id: feature.feature },
                quantity: feature.quantity,
              };
            } else {
              return {
                feature: { _id: feature.feature },
              };
            }
          })
        : [],
      ...data,
    };

    if (parent) {
      data.parent = { _id: parent };
    }

    delete data._id;
    delete data.path;

    try {
      const createdObj = await apiClient["/spaces"].post({
        body: data,
      });
      history.push(
        goto === "home" ? "/spaces" : "/spaces/" + createdObj._id + "/direction"
      );
    } catch (error) {
      console.log(error);
      setError(error.details);
    }
  };

  return space ? (
    <>
      <SpaceCreateView
        space={space}
        error={error}
        createItem={createItem}
        hierarchyItemList={hierarchyItemList}
        spaceList={spaceList}
        labelList={labelList}
        featureList={featureList}
      />
      {popupData && <Popup popupData={popupData} setPopupData={setPopupData} />}
    </>
  ) : null;
};

export default SpaceCreate;
