import { useEffect, useState } from "react";
import apiClient from "../../api";
import Popup from "../../components/Popup";
import AdminUsersView from "./view";

const AdminUsers = () => {
  const [error, setError] = useState(false);
  const [adminUsers, setAdminUsers] = useState();
  const [popupData, setPopupData] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const adminUsers = await apiClient["/adminUsers"].get();
        setAdminUsers(adminUsers);
      } catch (error) {
        setError(error.details);
      }
    })();
  }, []);

  const deleteItem = async (adminUserId) => {
    setPopupData({
      title: "Stai per eliminare l'elemento",
      message: "Questa è un'operazione che non potrai più annullare!",
      actions: [
        {
          label: "Annulla",
          effect: "close",
        },
        {
          label: "Elimina",
          type: "sensitive",
          effect: async () => {
            try {
              await apiClient["/adminUsers/{adminUserId}"].delete({
                pathParams: { adminUserId },
              });
              const adminUsers = await apiClient["/adminUsers"].get();
              setAdminUsers(adminUsers);
            } catch (error) {
              setError(error.details);
            }
          },
        },
      ],
    });
  };

  return (
    <>
      <AdminUsersView
        adminUsers={adminUsers}
        error={error}
        deleteItem={deleteItem}
      />
      {popupData && <Popup popupData={popupData} setPopupData={setPopupData} />}
    </>
  );
};

export default AdminUsers;
