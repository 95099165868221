import List from "../../components/List";
import Main from "../../components/Main";
import Message from "../../components/Message";
import Row from "../../components/Row";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useHistory } from "react-router-dom";

const DirectionTemplatesView = ({ error, directionTemplates, deleteItem }) => {
  const history = useHistory();

  return (
    <Main
      className="DirectionTemplates"
      path={["Template direzione"]}
      isParent
      error={error}
      mainActions={[
        {
          label: "Crea nuovo",
          type: "primary",
          effect: () => {
            history.push("/directionTemplates/new");
          },
        },
      ]}
    >
      <Message
        title=""
        message={`
          I template direzione non sono visibili direttamente dagli utenti dell'app, ma aiutano gli amministratori a creare step di direzioni ripetibili in più spazi.
      `}
      />
      <h2>Tutti i template direzione</h2>

      <List items={directionTemplates}>
        {(item) => {
          return (
            <Row
              to={`/directionTemplates/${item._id}`}
              key={item._id}
              title={item.displayName}
              actions={[
                {
                  title: "Modifica",
                  icon: EditIcon,
                  to: `/directionTemplates/${item._id}`,
                },
                {
                  title: "Elimina",
                  icon: DeleteIcon,
                  callback: () => {
                    deleteItem(item._id);
                  },
                },
              ]}
            />
          );
        }}
      </List>
    </Main>
  );
};
export default DirectionTemplatesView;
