import { useState } from "react";
import "./index.scss";

const TextInput = ({
  id,
  disabled,
  type,
  label,
  hint,
  initialValue,
  error,
  onChange,
  parse,
}) => {
  type = type || "text";
  onChange = onChange ? onChange : () => {};

  if (!initialValue) initialValue = "";
  const [currentValue, setCurrentValue] = useState(initialValue);

  const changeHandler = (event) => {
    const newValue = event.target.value;
    setCurrentValue(newValue);

    const valueToBeSaved = parse === "number" ? parseFloat(newValue) : newValue;
    onChange(valueToBeSaved, id);
  };

  return (
    <label className="TextInput">
      {label && <span className="labelText">{label}</span>}
      <input
        data-custom-id={id}
        disabled={disabled}
        type={type}
        value={currentValue}
        onChange={changeHandler}
      />
      {hint && <span className="hintText">{hint}</span>}
    </label>
  );
};

export default TextInput;
