import { useEffect, useState } from "react";
import apiClient from "../../api";
import Popup from "../../components/Popup";
import FrequentQuestionsView from "./view";

const FrequentQuestions = () => {
  const [error, setError] = useState(false);
  const [frequentQuestions, setFrequentQuestions] = useState();
  const [popupData, setPopupData] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const frequentQuestions = await apiClient["/frequentQuestions"].get();
        setFrequentQuestions(frequentQuestions);
      } catch (error) {
        setError(error.details);
      }
    })();
  }, []);

  const deleteItem = async (frequentQuestionId) => {
    setPopupData({
      title: "Stai per eliminare l'elemento",
      message: "Questa è un'operazione che non potrai più annullare!",
      actions: [
        {
          label: "Annulla",
          effect: "close",
        },
        {
          label: "Elimina",
          type: "sensitive",
          effect: async () => {
            try {
              await apiClient["/frequentQuestions/{frequentQuestionId}"].delete(
                {
                  pathParams: { frequentQuestionId },
                }
              );
              const frequentQuestions = await apiClient[
                "/frequentQuestions"
              ].get();
              setFrequentQuestions(frequentQuestions);
            } catch (error) {
              setError(error.details);
            }
          },
        },
      ],
    });
  };

  const orderHandler = async (up, id) => {
    const items = frequentQuestions;

    //Find and index of target item
    const targetItemIndex = items.findIndex((item) => item._id === id);

    //Find index of other item (previous if up is true, next if up is false)
    const otherItemIndex = up ? targetItemIndex - 1 : targetItemIndex + 1;

    //If the index is over the boundaries of the array, return, as there is nothing to swap
    if (otherItemIndex < 0 || otherItemIndex > items.length - 1) return;

    //Find the two items
    const targetItem = { ...items[targetItemIndex] };
    const otherItem = { ...items[otherItemIndex] };

    //Swap values
    const targetItemOrder = targetItem.order;
    const otherItemOrder = otherItem.order;
    targetItem.order = otherItemOrder;
    otherItem.order = targetItemOrder;

    //Put new values to API
    try {
      await Promise.all([
        apiClient["/frequentQuestions/{frequentQuestionId}"].put({
          pathParams: { frequentQuestionId: targetItem._id },
          body: {
            title: targetItem.title,
            text: targetItem.text,
            order: targetItem.order,
          },
        }),
        apiClient["/frequentQuestions/{frequentQuestionId}"].put({
          pathParams: { frequentQuestionId: otherItem._id },
          body: {
            title: otherItem.title,
            text: otherItem.text,
            order: otherItem.order,
          },
        }),
      ]);

      //Re-fetch list
      const frequentQuestions = await apiClient["/frequentQuestions"].get();
      setFrequentQuestions(frequentQuestions);
    } catch (error) {
      setError(error.details);
    }
  };

  return (
    <>
      <FrequentQuestionsView
        frequentQuestions={frequentQuestions}
        error={error}
        deleteItem={deleteItem}
        orderHandler={orderHandler}
      />
      {popupData && <Popup popupData={popupData} setPopupData={setPopupData} />}
    </>
  );
};

export default FrequentQuestions;
