import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import apiClient from "../../api";
import Popup from "../../components/Popup";
import AdminUserUpdateView from "./updateView";

const AdminUserUpdate = () => {
  const [error, setError] = useState(false);
  const [adminUser, setAdminUser] = useState();
  const [popupData, setPopupData] = useState(false);
  const { id } = useParams();

  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const adminUser = await apiClient["/adminUsers/{adminUserId}"].get({
          pathParams: { adminUserId: id },
        });
        setAdminUser(adminUser);
      } catch (error) {
        setError(error.details);
      }
    })();
  }, [id]);

  const updateItem = async ({ name, email, role }) => {
    const data = {
      name,
      email,
      role,
    };
    try {
      await apiClient["/adminUsers/{adminUserId}"].put({
        pathParams: { adminUserId: id },
        body: data,
      });
      history.push("/adminUsers");
    } catch (error) {
      console.log(error);
      setError(error.details);
    }
  };

  const deleteItem = async () => {
    setPopupData({
      title: "Stai per eliminare l'elemento",
      message: "Questa è un'operazione che non potrai più annullare!",
      actions: [
        {
          label: "Annulla",
          effect: "close",
        },
        {
          label: "Elimina",
          type: "sensitive",
          effect: async () => {
            try {
              await apiClient["/adminUsers/{adminUserId}"].delete({
                pathParams: { adminUserId: id },
              });
              history.push("/adminUsers");
            } catch (error) {
              setError(error.details);
            }
          },
        },
      ],
    });
  };

  return adminUser ? (
    <>
      <AdminUserUpdateView
        adminUser={adminUser}
        error={error}
        updateItem={updateItem}
        deleteItem={deleteItem}
      />
      {popupData && <Popup popupData={popupData} setPopupData={setPopupData} />}
    </>
  ) : null;
};

export default AdminUserUpdate;
