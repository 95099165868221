import TextInput from "../../components/TextInput";
import RichtextInput from "../../components/RichtextInput";
import FileInput from "../../components/FileInput";
import DateInput from "../../components/DateInput";
import ToggleInput from "../../components/ToggleInput";
import ColContainer from "../../components/ColContainer";
import Col from "../../components/Col";
import { API_URL } from "../../config/api";
import apiClient from "../../api";
import langFields from "../../utils/langFields";

const AnnouncementForm = ({ setItem, announcement }) => {
  return (
    <>
      <ColContainer>
        {langFields((code, label) => {
          return (
            <Col type="1-3" key={code}>
              <TextInput
                id={`title${code}`}
                label={`Titolo${label}`}
                initialValue={announcement[`title${code}`]}
                onChange={setItem}
              />
            </Col>
          );
        })}
      </ColContainer>
      <ColContainer>
        {langFields((code, label) => {
          return (
            <Col type="2-3" key={code}>
              <RichtextInput
                id={`text${code}`}
                label={`Corpo dell'annuncio${label}`}
                initialValue={announcement[`text${code}`]}
                onChange={setItem}
              />
            </Col>
          );
        })}
      </ColContainer>
      <ColContainer>
        <Col type="1-3">
          <DateInput
            id="publishDate"
            label="Visibile da"
            hint="L'annuncio rimarrà nascosto fino alla data indicata."
            initialValue={announcement.publishDate}
            onChange={setItem}
          />
        </Col>
        <Col type="1-3">
          <DateInput
            id="expiryDate"
            label="Visibile fino"
            hint="Facoltativo. Se imposti una data, l'annuncio diventerà nascosto a partire dalla data indicata."
            initialValue={announcement.expiryDate}
            onChange={setItem}
            isClearable
          />
        </Col>
      </ColContainer>
      <ColContainer>
        <Col type="1-3">
          <FileInput
            id="photoUrl"
            label="Fotografia"
            hint="Preferibile JPEG 1920px x 1080px"
            initialValue={announcement.photoUrl}
            onChange={setItem}
            FileRenderer={({ fileId }) => {
              return (
                <img
                  style={{ height: "250px" }}
                  alt={fileId}
                  src={`${API_URL}/mediaElements/${fileId}`}
                />
              );
            }}
            uploadHandler={async (file) => {
              const formData = new FormData();
              formData.append("file", file);

              const fileId = await apiClient["/mediaElements"].post({
                body: formData,
              });
              console.log(fileId);
              return fileId;
            }}
          />
        </Col>
        <Col type="1-3">
          <ToggleInput
            id="hidden"
            label="Nascosto"
            hint="Se acceso, l'annuncio rimane nascosto agli utenti finali"
            initialValue={announcement.hidden}
            onChange={setItem}
          />
        </Col>
      </ColContainer>
    </>
  );
};

export default AnnouncementForm;
