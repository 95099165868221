import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "./admin-logo.svg";

const LoginView = ({ action, isError }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  return (
    <main className="Login">
      <form
        className={`standardForm loginForm${isError ? " isError" : ""}`}
        onSubmit={(event) => {
          event.preventDefault();
          action(username, password);
        }}
      >
        <div className="logoWrapper">
          <img src={logo} alt="UniSR Maps Admin Area" />
        </div>
        <div className="formWrapper">
          <label>
            <span>Email</span>
            <input
              value={username}
              onChange={(event) => {
                setUsername(event.target.value);
              }}
              name="username"
              type="email"
            />
          </label>
          <label>
            <span>Password</span>
            <input
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              name="password"
              type="password"
            />
          </label>
          <button type="submit">Login</button>
          <br />
          <Link to="/resetPasswordRequest">Recupera la password</Link>
        </div>
      </form>
    </main>
  );
};

export default LoginView;
