import TextInput from "../../components/TextInput";
import RichtextInput from "../../components/RichtextInput";
import ColContainer from "../../components/ColContainer";
import Col from "../../components/Col";
import langFields from "../../utils/langFields";

const FrequentQuestionForm = ({ setItem, frequentQuestion }) => {
  return (
    <>
      <ColContainer>
        {langFields((code, label) => {
          return (
            <Col type="1-3" key={code}>
              <TextInput
                id={`title${code}`}
                label={`Domanda${label}`}
                initialValue={frequentQuestion[`title${code}`]}
                onChange={setItem}
              />
            </Col>
          );
        })}
      </ColContainer>
      <ColContainer>
        {langFields((code, label) => {
          return (
            <Col type="2-3" key={code}>
              <RichtextInput
                id={`text${code}`}
                label={`Testo${label}`}
                initialValue={frequentQuestion[`text${code}`]}
                onChange={setItem}
              />
            </Col>
          );
        })}
      </ColContainer>
    </>
  );
};

export default FrequentQuestionForm;
