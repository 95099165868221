import "./index.scss";

const Popup = ({ popupData, setPopupData }) => {
  const { title, message, type, actions } = popupData;

  return (
    <div className={`Popup ${type}`}>
      <div className="popupWindow">
        {title && <div className="title">{title}</div>}
        {message && <p>{message}</p>}
        <div className="actions">
          {actions &&
            actions.map((action, index) => {
              return (
                <button
                  key={index}
                  onClick={
                    action.effect === "close"
                      ? () => {
                          setPopupData(false);
                        }
                      : () => {
                          setPopupData(false);
                          action.effect();
                        }
                  }
                  className={action.type}
                >
                  {action.label}
                </button>
              );
            })}
        </div>
      </div>
      <div
        className="popupBackdrop"
        onClick={() => {
          setPopupData(false);
        }}
      ></div>
    </div>
  );
};

export default Popup;
