import { Close } from "@material-ui/icons";
import { useEffect, useState } from "react";
import "./index.scss";

const ErrorToast = ({ error }) => {
  const [currentError, setCurrentError] = useState(error);

  useEffect(() => {
    setCurrentError(error);
  }, [error]);

  return (
    <>
      {currentError && (
        <div className="ErrorToast">
          <p className="title">
            {currentError.type} - {currentError.code}
          </p>
          <p>{currentError.message}</p>
          <button
            onClick={() => {
              setCurrentError(false);
            }}
          >
            <Close />
          </button>
        </div>
      )}
    </>
  );
};

export default ErrorToast;
