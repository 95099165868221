import { useEffect, useState } from "react";
import apiClient from "../../api";
import Popup from "../../components/Popup";
import FeaturesView from "./view";

const Features = () => {
  const [error, setError] = useState(false);
  const [features, setFeatures] = useState();
  const [popupData, setPopupData] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const features = await apiClient["/features"].get();
        setFeatures(features);
      } catch (error) {
        setError(error.details);
      }
    })();
  }, []);

  const deleteItem = async (featureId) => {
    setPopupData({
      title: "Stai per eliminare l'elemento",
      message: "Questa è un'operazione che non potrai più annullare!",
      actions: [
        {
          label: "Annulla",
          effect: "close",
        },
        {
          label: "Elimina",
          type: "sensitive",
          effect: async () => {
            try {
              await apiClient["/features/{featureId}"].delete({
                pathParams: { featureId },
              });
              const features = await apiClient["/features"].get();
              setFeatures(features);
            } catch (error) {
              setError(error.details);
            }
          },
        },
      ],
    });
  };

  return (
    <>
      <FeaturesView features={features} error={error} deleteItem={deleteItem} />
      {popupData && <Popup popupData={popupData} setPopupData={setPopupData} />}
    </>
  );
};

export default Features;
