import List from "../../components/List";
import Main from "../../components/Main";
import Message from "../../components/Message";
import Row from "../../components/Row";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useHistory } from "react-router-dom";

const AdminUsersView = ({ error, adminUsers, deleteItem }) => {
  const history = useHistory();

  return (
    <Main
      className="AdminUsers"
      path={["Utenze di admin"]}
      isParent
      error={error}
      mainActions={[
        {
          label: "Crea nuovo",
          type: "primary",
          effect: () => {
            history.push("/adminUsers/new");
          },
        },
      ]}
    >
      <Message
        title=""
        message={`
          Le utenze di admin possono creare e modificare elementi all'interno di questa interfaccia.
        `}
      />
      <h2>Tutte le utenze di admin</h2>

      <List items={adminUsers}>
        {(item) => {
          return (
            <Row
              to={`/adminUsers/${item._id}`}
              key={item._id}
              title={`${item.name} (${item.email})`}
              actions={[
                {
                  title: "Modifica",
                  icon: EditIcon,
                  to: `/adminUsers/${item._id}`,
                },
                {
                  title: "Elimina",
                  icon: DeleteIcon,
                  callback: () => {
                    deleteItem(item._id);
                  },
                },
              ]}
            >
              <div className="role">{item.role}</div>
            </Row>
          );
        }}
      </List>
    </Main>
  );
};
export default AdminUsersView;
