import { useState } from "react";
import Main from "../../components/Main";
import setItemFactory from "../../utils/setItemFactory";
import { useHistory } from "react-router-dom";
import LabelForm from "./form";

const LabelUpdateView = ({ error, label, deleteItem, updateItem }) => {
  const [labelObj, setLabelObj] = useState(label);

  const setItem = setItemFactory(labelObj, setLabelObj);

  const history = useHistory();

  return label ? (
    <Main
      className="Label"
      path={["Etichette", label.singularDisplayName]}
      error={error}
      mainActions={[
        {
          label: "Annulla",
          effect: () => {
            history.push("/labels");
          },
        },
        {
          label: "Salva",
          type: "primary",
          effect: () => {
            updateItem(labelObj);
          },
        },
      ]}
      secondaryActions={[
        {
          label: "Elimina questo elemento",
          type: "sensitive",
          effect: deleteItem,
        },
      ]}
    >
      <LabelForm label={label} setItem={setItem} />
    </Main>
  ) : null;
};

export default LabelUpdateView;
