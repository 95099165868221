import { useState } from "react";
import { useHistory } from "react-router-dom";
import apiClient from "../../api";
import Popup from "../../components/Popup";
import LabelCreateView from "./createView";

const LabelCreate = () => {
  const [error, setError] = useState(false);
  const [popupData, setPopupData] = useState(false);

  const history = useHistory();

  const label = {};

  const createItem = async (data) => {
    delete data._id;
    try {
      await apiClient["/labels"].post({
        body: data,
      });
      history.push("/labels");
    } catch (error) {
      console.log(error);
      setError(error.details);
    }
  };

  return label ? (
    <>
      <LabelCreateView label={label} error={error} createItem={createItem} />
      {popupData && <Popup popupData={popupData} setPopupData={setPopupData} />}
    </>
  ) : null;
};

export default LabelCreate;
