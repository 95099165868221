import { useState } from "react";
import Main from "../../components/Main";
import setItemFactory from "../../utils/setItemFactory";
import { useHistory } from "react-router-dom";
import AdminUserForm from "./form";

const AdminUserCreateView = ({ error, adminUser, createItem }) => {
  const [adminUserObj, setAdminUserObj] = useState(adminUser);
  const setItem = setItemFactory(adminUserObj, setAdminUserObj);

  const history = useHistory();

  return adminUser ? (
    <Main
      className="AdminUser"
      path={["Utenze di admin", "Crea nuovo"]}
      error={error}
      mainActions={[
        {
          label: "Annulla",
          effect: () => {
            history.push("/adminUsers");
          },
        },
        {
          label: "Salva",
          type: "primary",
          effect: () => {
            createItem(adminUserObj);
          },
        },
      ]}
    >
      <AdminUserForm adminUser={adminUser} setItem={setItem} />
    </Main>
  ) : null;
};

export default AdminUserCreateView;
