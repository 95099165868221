import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import apiClient from "../../api";
import Col from "../../components/Col";
import ColContainer from "../../components/ColContainer";
import FileInput from "../../components/FileInput";
import Main from "../../components/Main";
import Message from "../../components/Message";
import MultipleInput from "../../components/MultipleInput";
import RichtextInput from "../../components/RichtextInput";
import SelectInput from "../../components/SelectInput";
import TextInput from "../../components/TextInput";
import { API_URL } from "../../config/api";
import langFields from "../../utils/langFields";

const DirectionUpdateView = ({
  space,
  spaceParent,
  setupType,
  error,
  directions,
  updateDirections,
  directionTemplateList,
}) => {
  const history = useHistory();

  const [currentDirections, setCurrentDirections] = useState();

  const editedDirections = {};

  useEffect(() => {
    setCurrentDirections(directions);
  }, [directions]);

  const saveSteps = (steps, entranceId) => {
    if (!entranceId) entranceId = "common";
    editedDirections[entranceId] = steps;
  };

  return space && setupType && currentDirections ? (
    <Main
      className="SpaceDirection"
      path={["Spazi", `Direzione per "${space.displayName}"`]}
      error={error}
      mainActions={[
        {
          label: "Annulla",
          effect: () => {
            history.push("/spaces");
          },
        },
        {
          label: "Salva",
          type: "primary",
          effect: () => {
            updateDirections(editedDirections);
          },
        },
      ]}
    >
      {setupType === "NOPARENT_NOENTRANCES" && (
        <>
          <Message
            title=""
            message="Inserisci le indicazioni per raggiungere l'ingresso principale di questo spazio a partire dall'esterno."
          />

          {currentDirections.map((direction) => {
            return (
              <div key={direction.space._id}>
                <h2>
                  Per raggiungere l'ingresso di "{direction.space.displayName}"
                  dall'esterno:
                </h2>
                <Form
                  direction={direction}
                  directionTemplateList={directionTemplateList}
                  setItem={(steps, label) => {
                    saveSteps(steps);
                  }}
                />
              </div>
            );
          })}
        </>
      )}

      {setupType === "NOPARENT_ENTRANCES" && (
        <>
          <Message
            title=""
            message="Questo spazio possiede diversi ingressi. Devi fornire indicazioni per raggiungere, dall'esterno, ciascun ingresso."
          />

          {currentDirections.map((direction) => {
            return (
              <div key={direction.entrance._id}>
                <h2>
                  Per raggiungere l'ingresso "{direction.entrance.displayName}"
                  di "{space.displayName}" dall'esterno:
                </h2>
                <Form
                  direction={direction}
                  directionTemplateList={directionTemplateList}
                  setItem={(steps, label) => {
                    saveSteps(steps, direction.entrance._id);
                  }}
                />
              </div>
            );
          })}
        </>
      )}

      {setupType === "PARENT_PARENTNOENTRANCES" && (
        <>
          <Message
            title=""
            message={`Inserisci le indicazioni per raggiungere l'ingresso principale di questo spazio a partire dall'ingresso di ${spaceParent.displayName}.`}
          />
          {currentDirections.map((direction) => {
            return (
              <div key={direction.space._id}>
                <h2>
                  Per raggiungere l'ingresso di "{direction.space.displayName}"
                  dall'ingresso di "{spaceParent.displayName}":
                </h2>
                <Form
                  direction={direction}
                  directionTemplateList={directionTemplateList}
                  setItem={(steps, label) => {
                    saveSteps(steps);
                  }}
                />
              </div>
            );
          })}
        </>
      )}

      {setupType === "PARENT_PARENTENTRANCES" && (
        <>
          <Message
            title=""
            message={`Questo spazio è figlio di uno spazio che possiede diversi ingressi. Devi fornire indicazioni per raggiungere questo spazio a partire da ciascun ingresso dello spazio genitore.`}
          />
          {currentDirections.map((direction) => {
            return (
              <div key={direction.entrance._id}>
                <h2>
                  Per raggiungere l'ingresso di "{direction.space.displayName}"
                  dall'ingresso "{direction.entrance.displayName}" di "
                  {spaceParent.displayName}":
                </h2>
                <Form
                  direction={direction}
                  directionTemplateList={directionTemplateList}
                  setItem={(steps, label) => {
                    saveSteps(steps, direction.entrance._id);
                  }}
                />
              </div>
            );
          })}
        </>
      )}
    </Main>
  ) : null;
};

const Form = ({ direction, setItem, directionTemplateList }) => {
  return (
    <MultipleInput
      orderable
      id="steps"
      initialValue={direction.steps}
      onChange={setItem}
    >
      {(item, changeHandler) => {
        return (
          <>
            <ColContainer>
              <Col type="1-3">
                <SelectInput
                  id="type"
                  label="Tipo di step"
                  initialValue={item.type}
                  onChange={changeHandler}
                  items={[
                    { value: "EXTERNAL", label: "Riferimento esterno" },
                    { value: "GRAPHICS", label: "Infografica o mappa" },
                    { value: "PHOTO", label: "Fotografia" },
                    { value: "TEXT", label: "Testo" },
                    { value: "TEMPLATE", label: "Template" },
                  ]}
                  valuePropertyName="value"
                  displayPropertyName="label"
                />
              </Col>
            </ColContainer>
            {item.type === "GRAPHICS" ? (
              <ColContainer>
                {langFields((code, label) => {
                  return (
                    <Col type="1-3" key={code}>
                      <FileInput
                        id={`photoUrl${code}`}
                        label={`Immagine${label}`}
                        hint="Preferibile SVG 400px x 400px"
                        initialValue={item[`photoUrl${code}`]}
                        onChange={changeHandler}
                        FileRenderer={({ fileId }) => {
                          return (
                            <img
                              style={{ height: "250px" }}
                              alt={fileId}
                              src={`${API_URL}/mediaElements/${fileId}`}
                            />
                          );
                        }}
                        uploadHandler={async (file) => {
                          const formData = new FormData();
                          formData.append("file", file);

                          const fileId = await apiClient["/mediaElements"].post(
                            {
                              body: formData,
                            }
                          );
                          console.log(fileId);
                          return fileId;
                        }}
                      />
                    </Col>
                  );
                })}
              </ColContainer>
            ) : null}
            {item.type === "PHOTO" ? (
              <ColContainer>
                {langFields((code, label) => {
                  return (
                    <Col type="1-3" key={code}>
                      <FileInput
                        id={`photoUrl${code}`}
                        label={`Fotografia${label}`}
                        hint="Preferibile JPEG 1080px x 1080px"
                        initialValue={item[`photoUrl${code}`]}
                        onChange={changeHandler}
                        FileRenderer={({ fileId }) => {
                          return (
                            <img
                              style={{ height: "250px" }}
                              alt={fileId}
                              src={`${API_URL}/mediaElements/${fileId}`}
                            />
                          );
                        }}
                        uploadHandler={async (file) => {
                          const formData = new FormData();
                          formData.append("file", file);

                          const fileId = await apiClient["/mediaElements"].post(
                            {
                              body: formData,
                            }
                          );
                          return fileId;
                        }}
                      />
                    </Col>
                  );
                })}
              </ColContainer>
            ) : null}
            {item.type === "TEXT" ? (
              <ColContainer>
                {langFields((code, label) => {
                  return (
                    <Col type="2-3" key={code}>
                      <RichtextInput
                        id={`text${code}`}
                        label={`Testo${label}`}
                        initialValue={item[`text${code}`]}
                        onChange={changeHandler}
                      />
                    </Col>
                  );
                })}
              </ColContainer>
            ) : null}
            {item.type === "TEMPLATE" ? (
              <ColContainer>
                <Col type="1-3">
                  <SelectInput
                    id="templateId"
                    label="Template"
                    initialValue={item.templateId}
                    onChange={changeHandler}
                    items={directionTemplateList}
                    valuePropertyName="_id"
                    displayPropertyName="displayName"
                  />
                </Col>
              </ColContainer>
            ) : null}
            {item.type === "EXTERNAL" ? (
              <>
                <ColContainer>
                  <Col type="2-3">
                    <Message
                      title=""
                      message={
                        "Inserisci l'indirizzo e le coordinate del punto di entrata."
                      }
                    />
                  </Col>
                </ColContainer>
                <ColContainer>
                  <Col type="1-3">
                    <TextInput
                      id="lat"
                      label="Latitudine"
                      initialValue={item.lat}
                      onChange={changeHandler}
                      parse="number"
                    />
                  </Col>
                  <Col type="1-3">
                    <TextInput
                      id="long"
                      label="Longitudine"
                      initialValue={item.long}
                      onChange={changeHandler}
                      parse="number"
                    />
                  </Col>
                </ColContainer>{" "}
                <ColContainer>
                  <Col type="2-3">
                    <TextInput
                      id="addressDisplayName"
                      label="Indirizzo"
                      hint="Inserisci l'indirizzo completo (es. Via Giosuè Carducci 11 - Milano MI)"
                      initialValue={item.addressDisplayName}
                      onChange={changeHandler}
                    />
                  </Col>
                </ColContainer>
                {item.addressDisplayName ? (
                  <ColContainer>
                    <Col type="2-3">
                      <p>Indirizzi generati:</p>
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                              item.addressDisplayName
                            )}`}
                          >
                            Google Maps
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://citymapper.com/directions?endcoord=${item.lat}%2C${item.long}&endaddress=${item.addressDisplayName}`}
                          >
                            Citymapper
                          </a>
                        </li>
                      </ul>
                    </Col>
                  </ColContainer>
                ) : null}
              </>
            ) : null}
          </>
        );
      }}
    </MultipleInput>
  );
};

export default DirectionUpdateView;
