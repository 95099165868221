import { useState } from "react";
import "./index.scss";

const FileInput = ({
  id,
  disabled,
  label,
  hint,
  initialValue,
  error,
  onChange,
  uploadHandler,
  FileRenderer,
}) => {
  const [currentValue, setCurrentValue] = useState(initialValue);
  const [isUploading, setIsUploading] = useState(false);

  FileRenderer = !FileRenderer
    ? ({ fileId }) => {
        return <div>File caricato ({fileId})</div>;
      }
    : FileRenderer;

  const fileChosenHandler = async (event) => {
    const fileToBeUploaded = event.target.files[0];
    setIsUploading(true);
    const newFileId = await uploadHandler(fileToBeUploaded);
    onChange(newFileId, id);
    setCurrentValue(newFileId);
    setIsUploading(false);
  };

  const removeHandler = () => {
    onChange(undefined, id);
    setCurrentValue(undefined);
  };

  return (
    <div className="FileInput">
      {label && <span className="labelText">{label}</span>}
      <div className="fileArea">
        {!currentValue ? (
          <>
            {isUploading ? (
              <span className="uploadingNotice">Uploading... </span>
            ) : null}
            <input type="file" name="file" onChange={fileChosenHandler} />
          </>
        ) : (
          <div className="FileRenderer">
            <FileRenderer fileId={currentValue} />
            <div className="actions">
              <button onClick={removeHandler}>Rimuovi file</button>
            </div>
          </div>
        )}
      </div>
      {hint && <span className="hintText">{hint}</span>}
    </div>
  );
};

export default FileInput;
