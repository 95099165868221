import Main from "../../components/Main";
import { useHistory } from "react-router-dom";
import SpaceForm from "./form";

import UserContext from "../../contexts/UserContext";
import { useContext } from "react";

const SpaceUpdateView = ({
  error,
  space,
  deleteItem,
  updateItem,
  spaceList,
  hierarchyItemList,
  labelList,
  featureList,
}) => {
  /* const [spaceObj, setSpaceObj] = useState(
    space
  );

  const setItem = setItemFactory(spaceObj, setSpaceObj);
*/

  const { user } = useContext(UserContext);

  let spaceObj = space;

  const setItem = (newValue, property) => {
    spaceObj[property] = newValue;
  };

  const history = useHistory();

  return space ? (
    <Main
      className="Space"
      path={["Spazi", space.displayName]}
      error={error}
      mainActions={
        user.role !== "EDITORIAL"
          ? [
              {
                label: "Annulla",
                effect: () => {
                  history.push("/spaces");
                },
              },
              {
                label: "Salva",
                type: "primary",
                effect: () => {
                  updateItem(spaceObj);
                },
              },
              {
                label: "Salva e passa alle direzioni",
                type: "primary",
                effect: () => {
                  updateItem(spaceObj, "directions");
                },
              },
            ]
          : [
              {
                label: "Annulla",
                effect: () => {
                  history.push("/spaces");
                },
              },
              {
                label: "Salva",
                type: "primary",
                effect: () => {
                  updateItem(spaceObj);
                },
              },
            ]
      }
      secondaryActions={[
        {
          label: "Elimina questo elemento",
          type: "sensitive",
          effect: deleteItem,
        },
      ]}
    >
      <SpaceForm
        space={space}
        setItem={setItem}
        spaceList={spaceList}
        hierarchyItemList={hierarchyItemList}
        labelList={labelList}
        featureList={featureList}
      />
    </Main>
  ) : null;
};

export default SpaceUpdateView;
