import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Main from "../../components/Main";
import navItems from "../../config/navitems";
import UserContext from "../../contexts/UserContext";
import "./index.scss";

const Homepage = () => {
  const { user } = useContext(UserContext);
  const [error /* , setError */] = useState(false);

  return (
    <Main className="Homepage" path={["Homepage"]} isParent error={error}>
      <p>
        <b>Ciao {user.name}!</b>
        <br />
        Inizia esplorando una di queste sezioni.
      </p>

      {navItems.map((navGroup, index) => {
        return (
          <div key={index}>
            {navGroup.filter((navItem) => navItem[2].includes(user.role))
              .length ? (
              <>
                <ul className="navGroup">
                  {navGroup
                    .filter((navItem) => navItem[2].includes(user.role))
                    .map((navItem) => {
                      return (
                        <li key={navItem[1]}>
                          <Link to={navItem[1]}>
                            <h2>{navItem[0]} &gt;</h2>
                            {navItem[2] ? (
                              <p>{navItem[3].description}</p>
                            ) : null}
                          </Link>
                        </li>
                      );
                    })}
                </ul>
                <hr />
              </>
            ) : null}
          </div>
        );
      })}
    </Main>
  );
};

export default Homepage;
