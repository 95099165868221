import { Link } from "react-router-dom";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import "./index.scss";

const Row = ({ title, to, actions, children, id, orderable, orderHandler }) => {
  return (
    <li className="Row">
      <div className="title">
        {orderable ? (
          <div className="orderActions">
            <button
              onClick={() => {
                orderHandler(true, id);
              }}
            >
              <KeyboardArrowUpIcon />
            </button>
            <button
              onClick={() => {
                orderHandler(false, id);
              }}
            >
              <KeyboardArrowDownIcon />
            </button>
          </div>
        ) : null}
        {to ? (
          <Link className="titleText" to={to}>
            {title}
          </Link>
        ) : (
          <span className="titleText">{title}</span>
        )}
        {actions && (
          <div className="actions">
            {actions.map((action, index) => {
              return action.to ? (
                <Link title={action.title} key={index} to={action.to}>
                  {<action.icon />}
                </Link>
              ) : (
                <button
                  title={action.title}
                  key={index}
                  onClick={action.callback}
                >
                  {<action.icon />}
                </button>
              );
            })}
          </div>
        )}
      </div>
      {children && <div className="more">{children}</div>}
    </li>
  );
};

export default Row;
