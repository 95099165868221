import { useState } from "react";
import Main from "../../components/Main";
import setItemFactory from "../../utils/setItemFactory";
import { useHistory } from "react-router-dom";
import AnnouncementForm from "./form";

const AnnouncementCreateView = ({ error, announcement, createItem }) => {
  const [announcementObj, setAnnouncementObj] = useState(announcement);
  const setItem = setItemFactory(announcementObj, setAnnouncementObj);

  const history = useHistory();

  return announcement ? (
    <Main
      className="Announcement"
      path={["Annunci", "Crea nuovo"]}
      error={error}
      mainActions={[
        {
          label: "Annulla",
          effect: () => {
            history.push("/announcements");
          },
        },
        {
          label: "Salva",
          type: "primary",
          effect: () => {
            createItem(announcementObj);
          },
        },
      ]}
    >
      <AnnouncementForm announcement={announcement} setItem={setItem} />
    </Main>
  ) : null;
};

export default AnnouncementCreateView;
