import { useState } from "react";
import { useHistory } from "react-router-dom";
import apiClient from "../../api";
import Popup from "../../components/Popup";
import OnboardingStepCreateView from "./createView";

const OnboardingStepCreate = () => {
  const [error, setError] = useState(false);
  const [popupData, setPopupData] = useState(false);

  const history = useHistory();

  const onboardingStep = {};

  const createItem = async (data) => {
    delete data._id;
    try {
      await apiClient["/onboardingSteps"].post({
        body: data,
      });
      history.push("/onboardingSteps");
    } catch (error) {
      console.log(error);
      setError(error.details);
    }
  };

  return onboardingStep ? (
    <>
      <OnboardingStepCreateView
        onboardingStep={onboardingStep}
        error={error}
        createItem={createItem}
      />
      {popupData && <Popup popupData={popupData} setPopupData={setPopupData} />}
    </>
  ) : null;
};

export default OnboardingStepCreate;
