import { useState } from "react";
import { DateTime } from "luxon";
import DatePicker from "react-datepicker";
import "./index.scss";

const TextInput = ({
  id,
  disabled,
  label,
  hint,
  initialValue,
  isClearable,
  error,
  onChange,
}) => {
  onChange = onChange ? onChange : () => {};

  //if (!initialValue) initialValue = "";
  const [currentValue, setCurrentValue] = useState(
    initialValue ? new Date(initialValue) : ""
  );

  const changeHandler = (date) => {
    const newValue = date;
    setCurrentValue(newValue);
    onChange(DateTime.fromJSDate(newValue).toISO(), id);
  };

  return (
    <label className="DateInput">
      {label && <span className="labelText">{label}</span>}
      <DatePicker
        disabled={disabled}
        selected={currentValue}
        onChange={changeHandler}
        showTimeSelect
        dateFormat="dd/MM/yyyy HH:mm"
        timeFormat="HH:mm"
        isClearable={isClearable}
      />
      {hint && <span className="hintText">{hint}</span>}
    </label>
  );
};

export default TextInput;
