import Message from "../../components/Message";
import TextInput from "../../components/TextInput";
import ToggleInput from "../../components/ToggleInput";
import SelectInput from "../../components/SelectInput";
import ColContainer from "../../components/ColContainer";
import Col from "../../components/Col";
import langFields from "../../utils/langFields";

const HierarchyItemForm = ({ setItem, hierarchyItem, hierarchyItemList }) => {
  return (
    <>
      <Message
        title="La modifica della gerarchia è un'operazione rischiosa!"
        type="warning"
        message="
          Gli elementi di gerarchia sono un componente sensibile della struttura dati di questa applicazione. 
          Modifica questi dati solo ed esclusivamente se sei consapevole di cosa stai facendo, 
          e di quali sono le conseguenze delle tue azioni.
      "
      />
      <ColContainer>
        {langFields((code, label) => {
          return (
            <Col type="1-3" key={code}>
              <TextInput
                id={`singularDisplayName${code}`}
                label={`Nome singolare${label}`}
                hint={`Il nome di questo elemento al singolare${label}`}
                initialValue={hierarchyItem[`singularDisplayName${code}`]}
                onChange={setItem}
              />
            </Col>
          );
        })}
      </ColContainer>
      <ColContainer>
        {langFields((code, label) => {
          return (
            <Col type="1-3" key={code}>
              <TextInput
                id={`pluralDisplayName${code}`}
                label={`Nome plurale${label}`}
                hint={`Il nome di questo elemento al plurale${label}`}
                initialValue={hierarchyItem[`pluralDisplayName${code}`]}
                onChange={setItem}
              />
            </Col>
          );
        })}
      </ColContainer>

      <ColContainer>
        <Col type="1-3">
          <ToggleInput
            id="isPrimary"
            label="È primario"
            hint="Questo è il figlio principale del genitore di questo elemento."
            initialValue={hierarchyItem.isPrimary}
            onChange={setItem}
          />
        </Col>
        <Col type="1-3">
          <ToggleInput
            id="isHidden"
            label="Questi spazi sono nascosti"
            hint="Gli spazi a questo livello non sono ricercabili dagli utenti dell'app."
            initialValue={hierarchyItem.isHidden}
            onChange={setItem}
          />
        </Col>
      </ColContainer>

      <ColContainer>
        <Col type="1-3">
          <SelectInput
            id="parentId"
            label="Elemento genitore"
            hint="Indicare di chi è figlio questo elemento."
            initialValue={hierarchyItem.parentId}
            onChange={setItem}
            items={hierarchyItemList}
            valuePropertyName="_id"
            displayPropertyName="singularDisplayName"
            nullable
          />
        </Col>
      </ColContainer>
    </>
  );
};

export default HierarchyItemForm;
