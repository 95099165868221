import TextInput from "../../components/TextInput";
import SelectInput from "../../components/SelectInput";
import ColContainer from "../../components/ColContainer";
import Col from "../../components/Col";
import Message from "../../components/Message";

const AdminUserForm = ({ setItem, adminUser }) => {
  return (
    <>
      <ColContainer>
        <Col type="1-3">
          <TextInput
            id="name"
            label="Nome"
            initialValue={adminUser.name}
            onChange={setItem}
          />
        </Col>
        <Col type="1-3">
          <TextInput
            id="email"
            label="Email"
            initialValue={adminUser.email}
            onChange={setItem}
          />
        </Col>
        <Col type="1-3">
          <SelectInput
            id="role"
            label="Ruolo"
            hint="Indicare il ruolo di questo utente"
            initialValue={adminUser.role}
            onChange={setItem}
            items={[
              { value: "SUPERADMIN", label: "SUPERADMIN" },
              { value: "ADMIN", label: "ADMIN" },
              { value: "EDITORIAL", label: "EDITORIAL" },
            ]}
            valuePropertyName="value"
            displayPropertyName="label"
          />
        </Col>
      </ColContainer>
      <Message
        title="Livelli di amministrazione"
        message={`
          SUPERADMIN: ha accesso a tutti i livelli; ADMIN: ha accesso a tutti i livelli, tranne la gerarchia e la gestione degli utenti; EDITORIAL: può solo gestire gli annunci, le domande frequenti, e le fotografie e le descrizioni degli spazi.
        `}
      />
    </>
  );
};

export default AdminUserForm;
