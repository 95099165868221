import List from "../../components/List";
import Main from "../../components/Main";
import Message from "../../components/Message";
import Row from "../../components/Row";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useHistory } from "react-router-dom";

const FeaturesView = ({ error, features, deleteItem }) => {
  const history = useHistory();

  return (
    <Main
      className="Features"
      path={["Caratteristiche"]}
      isParent
      error={error}
      mainActions={[
        {
          label: "Crea nuovo",
          type: "primary",
          effect: () => {
            history.push("/features/new");
          },
        },
      ]}
    >
      <Message
        title=""
        message={`
          Le caratteristiche possono essere associate agli spazi per descriverne le proprietà.
      `}
      />
      <h2>Tutte le caratteristiche</h2>

      <List items={features}>
        {(item) => {
          return (
            <Row
              to={`/features/${item._id}`}
              key={item._id}
              title={item.singularDisplayName}
              actions={[
                {
                  title: "Modifica",
                  icon: EditIcon,
                  to: `/features/${item._id}`,
                },
                {
                  title: "Elimina",
                  icon: DeleteIcon,
                  callback: () => {
                    deleteItem(item._id);
                  },
                },
              ]}
            />
          );
        }}
      </List>
    </Main>
  );
};
export default FeaturesView;
