import { useState } from "react";
import Main from "../../components/Main";
import setItemFactory from "../../utils/setItemFactory";
import { useHistory } from "react-router-dom";
import LegalNoteForm from "./form";

const LegalNoteCreateView = ({ error, legalNote, createItem }) => {
  const [legalNoteObj, setLegalNoteObj] = useState(legalNote);
  const setItem = setItemFactory(legalNoteObj, setLegalNoteObj);

  const history = useHistory();

  return legalNote ? (
    <Main
      className="LegalNote"
      path={["Domande frequenti", "Crea nuovo"]}
      error={error}
      mainActions={[
        {
          label: "Annulla",
          effect: () => {
            history.push("/legalNotes");
          },
        },
        {
          label: "Salva",
          type: "primary",
          effect: () => {
            createItem(legalNoteObj);
          },
        },
      ]}
    >
      <LegalNoteForm legalNote={legalNote} setItem={setItem} />
    </Main>
  ) : null;
};

export default LegalNoteCreateView;
