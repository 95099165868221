const navItems = [
  [
    [
      "Home",
      "/",
      ["SUPERADMIN", "ADMIN", "EDITORIAL"],
      {
        exact: true,
        description: "Il punto di partenza dell'interfaccia di amministrazione",
      },
    ],
  ],
  [
    [
      "Spazi",
      "/spaces",
      ["SUPERADMIN", "ADMIN", "EDITORIAL"],
      {
        description:
          "Crea e modifica gli spazi, le loro caratteristiche e le relazioni che intercorrono fra loro.",
      },
    ],
    [
      "Template direzione",
      "/directionTemplates",
      ["SUPERADMIN", "ADMIN"],
      {
        description:
          "Crea e modifica i template di direzione: modelli riutilizzabili di indicazioni da utilizzare in più spazi.",
      },
    ],
  ],
  [
    [
      "Gerarchia",
      "/hierarchyItems",
      ["SUPERADMIN"],
      {
        description:
          "Gestisci la gerarchia principale degli spazi all'interno dell'app.",
      },
    ],
    [
      "Etichette",
      "/labels",
      ["SUPERADMIN", "ADMIN"],
      {
        description:
          "Gestisci le etichette che possono essere associate agli spazi.",
      },
    ],
    [
      "Caratteristiche",
      "/features",
      ["SUPERADMIN", "ADMIN"],
      {
        description:
          "Gestisci le caratteristiche che possono essere associate agli spazi",
      },
    ],
  ],
  [
    [
      "Annunci",
      "/announcements",
      ["SUPERADMIN", "ADMIN", "EDITORIAL"],
      {
        description: "Crea o modifica gli annunci importanti.",
      },
    ],
    [
      "Domande frequenti",
      "/frequentQuestions",
      ["SUPERADMIN", "ADMIN", "EDITORIAL"],
      {
        description: "Crea o modifica le domande frequenti.",
      },
    ],
    [
      "Note legali",
      "/legalNotes",
      ["SUPERADMIN", "ADMIN"],
      {
        description: "Crea o modifica pagine dedicate alle note legali.",
      },
    ],
    [
      "Step di onboarding",
      "/onboardingSteps",
      ["SUPERADMIN", "ADMIN"],
      {
        description:
          "Crea o modifica le slide mostrate agli utenti alla prima visita dell'app.",
      },
    ],
  ],
  [
    [
      "Utenze di admin",
      "/adminUsers",
      ["SUPERADMIN"],
      {
        description: "Gestisci le utenze di admin.",
      },
    ],
  ],
  [
    [
      "Backup del database",
      "/backups",
      ["SUPERADMIN"],
      {
        description: "Crea o ripristina backup del database.",
      },
    ],
    [
      "Documentazione API",
      "/apidocs",
      ["SUPERADMIN", "ADMIN"],
      {
        description: "Consulta la documentazione delle API.",
      },
    ],
  ],
];

export default navItems;
