import { DateTime } from "luxon";
import apiClient from "../api";

let session = {};

const set = async (newSession, setUser) => {
  newSession.exipire = DateTime.now()
    .plus({
      seconds: newSession.expires_in,
    })
    .toISO();

  session = newSession;
  window.localStorage.setItem("session", JSON.stringify(session));

  try {
    const user = await apiClient["/me"].get();
    setUser(user);
  } catch (error) {
    setUser({});
  }
};

const checkAndRefresh = async () => {
  if (DateTime.now().plus({ minutes: 5 }) > DateTime.fromISO(session.exipire)) {
    try {
      const sessionResult = await apiClient["/auth/token"].post({
        body: {
          refresh_token: session.refresh_token,
          grant_type: "refresh_token",
        },
        contentType: "application/x-www-form-urlencoded",
      });
      sessionResult.exipire = DateTime.now()
        .plus({
          seconds: sessionResult.expires_in,
        })
        .toISO();

      session = sessionResult;
      window.localStorage.setItem("session", JSON.stringify(session));
    } catch (error) {
      window.localStorage.removeItem("session");
    }
  }
};

const get = async () => {
  await checkAndRefresh();
  return session;
};

const load = async (setUser) => {
  //load from localStorage
  const sessionFromStorage = window.localStorage.getItem("session");

  //if something is saved
  if (sessionFromStorage) {
    let newSession = JSON.parse(sessionFromStorage);
    session = newSession;
    await checkAndRefresh();

    try {
      const user = await apiClient["/me"].get();
      setUser(user);
    } catch (error) {
      setUser({});
    }
  }
};

const destroy = () => {
  session = {};
  window.localStorage.removeItem("session");
};

const SessionStore = { set, get, load, destroy };

export default SessionStore;
