import { useState } from "react";
import Main from "../../components/Main";
import setItemFactory from "../../utils/setItemFactory";
import { useHistory } from "react-router-dom";
import LabelForm from "./form";

const LabelCreateView = ({ error, label, createItem }) => {
  const [labelObj, setLabelObj] = useState(label);
  const setItem = setItemFactory(labelObj, setLabelObj);

  const history = useHistory();

  return label ? (
    <Main
      className="Label"
      path={["Etichette", "Crea nuovo"]}
      error={error}
      mainActions={[
        {
          label: "Annulla",
          effect: () => {
            history.push("/labels");
          },
        },
        {
          label: "Salva",
          type: "primary",
          effect: () => {
            createItem(labelObj);
          },
        },
      ]}
    >
      <LabelForm label={label} setItem={setItem} />
    </Main>
  ) : null;
};

export default LabelCreateView;
