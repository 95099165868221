import List from "../../components/List";
import Main from "../../components/Main";
import Message from "../../components/Message";
import Row from "../../components/Row";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DirectionsIcon from "@material-ui/icons/Directions";
import { useHistory } from "react-router-dom";
import "./index.scss";
import UserContext from "../../contexts/UserContext";
import { useContext, useState } from "react";
import SelectInput from "../../components/SelectInput";

const SpacesView = ({ error, spaces, deleteItem }) => {
  const history = useHistory();

  const { user } = useContext(UserContext);

  const higherSpaces = spaces.filter(
    (space) =>
      space.path && space.path.length < 3 && space.hierarchyItem?.isPrimary
  );

  const [filteredSpaces, setFilteredSpaces] = useState(spaces);

  const setFilter = (id) => {
    if (id === null) {
      setFilteredSpaces(spaces);
    } else {
      const filteredSpaces = spaces.filter((space) => {
        return space.path && space.path.some((ancestor) => ancestor._id === id);
      });
      setFilteredSpaces(filteredSpaces);
    }
  };

  return (
    <Main
      className="Spaces"
      path={["Spazi"]}
      isParent
      error={error}
      mainActions={
        user.role !== "EDITORIAL"
          ? [
              {
                label: "Crea nuovo",
                type: "primary",
                effect: () => {
                  history.push("/spaces/new");
                },
              },
            ]
          : []
      }
    >
      <Message
        title=""
        message={`
          Gli spazi sono gli elementi principali dell'app. Ogni spazio può essere un campus, un edificio, un piano, un'aula, un servizio...
      `}
      />
      <h2>Filtri</h2>

      <SelectInput
        id="parentFilter"
        label="Visualizza solo i figli di"
        hint=""
        initialValue={null}
        onChange={setFilter}
        items={higherSpaces}
        valuePropertyName="_id"
        displayPropertyName="displayName"
        subtitlePropertyName="pathText"
        nullable
      />

      <h2>Tutti gli spazi</h2>

      <List items={filteredSpaces}>
        {(item) => {
          return (
            <Row
              to={`/spaces/${item._id}`}
              key={item._id}
              title={item.displayName}
              actions={
                user.role !== "EDITORIAL"
                  ? [
                      {
                        title: "Modifica",
                        icon: EditIcon,
                        to: `/spaces/${item._id}`,
                      },
                      {
                        title: "Elimina",
                        icon: DeleteIcon,
                        callback: () => {
                          deleteItem(item._id);
                        },
                      },
                      {
                        title: "Direzioni",
                        icon: DirectionsIcon,
                        to: `/spaces/${item._id}/direction`,
                      },
                    ]
                  : [
                      {
                        title: "Modifica",
                        icon: EditIcon,
                        to: `/spaces/${item._id}`,
                      },
                    ]
              }
            >
              <div className="path">{item.pathText}</div>
            </Row>
          );
        }}
      </List>
    </Main>
  );
};
export default SpacesView;
